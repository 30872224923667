export default {
  'profile.edit': 'Cập nhật hồ sơ',
  'profile.update': 'Lưu thay đổi',
  'profile.fullName': 'Họ và tên',
  'profile.userName': 'Tên đăng nhập',
  'profile.phone': 'Số điện thoại',
  'profile.roles': 'Vai trò',
  'users.roles': 'Vai trò',
  'users.birthday': 'Ngày sinh',
  'profile._password': 'Mật khẩu',
  'profile.createDateTime': 'Ngày tạo',
  'profile.isActive': 'Trạng thái hoạt động',
  'profile.change.password.title': 'Đặt lại mật khẩu',
  'profile.change.password.btn': 'Đổi mật khẩu',
  'profile.change.password.subtitle': 'Vui lòng chọn mật khẩu mới để đăng nhập',
  'profile.newPassword': 'Nhập mật khẩu mới',
  'profile.confirm.newPassword': 'Xác nhận mật khẩu mới',
  'password.not.match': 'Mật khẩu không khớp',
  'profile.name': 'Hồ sơ',
  'profile.oldPassword': 'Nhập mật khẩu cũ',
  'profile.title': 'Thông tin cá nhân',

  'profile.tariffPackagesMonth': 'Gói cước trong tháng',
  'profile.tariffPackagesTime': 'Thời gian hết hạn:',
  'profile.tariffPackagesAuto': 'Gia hạn tự động:',
  'profile.tariffPackages-ghn': 'Gia hạn ngay:',
  'profile.tariffPackages-btn-ghn': 'Gia hạn ',
  'profile.tariffPackagesUpdate': 'Nâng cấp gói cước:',
  'profile.tariffPackagesUpdate-btn': 'Nâng cấp',
  'profile.tariffPackages-history': 'Lịch sử gia hạn',
  'profile.tariffPackages-info': 'Thông tin thẻ gia hạn',
  'profile.tariffPackages.title': 'Thông tin gói cước',
  'renewal-history.title': 'Lịch sử gia hạn gói cước',
  'renewal-history-createdAt': 'Thời gian gia hạn',
  'profile.code.title': 'Tài khoản bị ngưng hoạt động',
  'profile.code.content':
    'Tài khoản của bạn hiện đang ngưng hoạt động nên không thể truy cập vào hệ thống. Vui lòng liên hệ admin hệ thống để giải quyết.',

  'renewal-history.payer.name': 'Người gia hạn',
  'renewal-history.payer.phoneNumber': 'Số điện thoại',
  'renewal-history.gracetime': 'Thời gian gia hạn',
  'renewal-history.price': 'Giá gói cước',
  'renewal-history.paymentMethod': 'Phương thức thanh toán',
  'renewal-history.capacity': 'Gói cước gia hạn',
  'message.required.oldPassword': 'Vui lòng nhập mật khẩu cũ',
  'message.pattern.oldPassword': 'Mật khẩu cũ không đúng định dạng',
  'message.required.password': 'Vui lòng nhập mật khẩu mới',
  'message.pattern.password': 'Mật khẩu mới không đúng định dạng',
  'message.required.confirmPassword': 'Vui lòng xác nhận lại mật khẩu mới',
  
};
