import React from 'react';

import Icon from '@ant-design/icons';

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      d="M14.2024 26.125H21.796M18 3.375V5M28.3415 7.6585L27.1926 8.80737M32.625 18H31M5 18H3.375M8.80737 8.80737L7.6585 7.6585M12.254 23.746C11.1179 22.6096 10.3443 21.1619 10.031 19.5858C9.71763 18.0098 9.87866 16.3762 10.4937 14.8917C11.1087 13.4072 12.1501 12.1384 13.4862 11.2457C14.8223 10.353 16.3931 9.87651 18 9.87651C19.6069 9.87651 21.1777 10.353 22.5138 11.2457C23.8499 12.1384 24.8913 13.4072 25.5063 14.8917C26.1213 16.3762 26.2824 18.0098 25.969 19.5858C25.6557 21.1619 24.8821 22.6096 23.746 23.746L22.8555 24.6349C22.3464 25.1441 21.9426 25.7486 21.6671 26.4138C21.3916 27.0791 21.2499 27.7921 21.25 28.5121V29.375C21.25 30.237 20.9076 31.0636 20.2981 31.6731C19.6886 32.2826 18.862 32.625 18 32.625C17.138 32.625 16.3114 32.2826 15.7019 31.6731C15.0924 31.0636 14.75 30.237 14.75 29.375V28.5121C14.75 27.0577 14.1715 25.6619 13.1445 24.6349L12.254 23.746Z"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const LeaderIcon = props => <Icon component={icon} {...props} />;
export default LeaderIcon;
