import React from 'react';

import Icon from '@ant-design/icons';

const closeIconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29279 5.29357C5.48031 5.1061 5.73462 5.00078 5.99979 5.00078C6.26495 5.00078 6.51926 5.1061 6.70679 5.29357L11.9998 10.5866L17.2928 5.29357C17.385 5.19806 17.4954 5.12188 17.6174 5.06947C17.7394 5.01706 17.8706 4.98947 18.0034 4.98832C18.1362 4.98717 18.2678 5.01247 18.3907 5.06275C18.5136 5.11303 18.6253 5.18728 18.7192 5.28117C18.8131 5.37507 18.8873 5.48672 18.9376 5.60962C18.9879 5.73251 19.0132 5.86419 19.012 5.99697C19.0109 6.12975 18.9833 6.26097 18.9309 6.38297C18.8785 6.50498 18.8023 6.61532 18.7068 6.70757L13.4138 12.0006L18.7068 17.2936C18.8889 17.4822 18.9897 17.7348 18.9875 17.997C18.9852 18.2592 18.88 18.51 18.6946 18.6954C18.5092 18.8808 18.2584 18.986 17.9962 18.9882C17.734 18.9905 17.4814 18.8897 17.2928 18.7076L11.9998 13.4146L6.70679 18.7076C6.51818 18.8897 6.26558 18.9905 6.00339 18.9882C5.74119 18.986 5.49038 18.8808 5.30497 18.6954C5.11956 18.51 5.01439 18.2592 5.01211 17.997C5.00983 17.7348 5.11063 17.4822 5.29279 17.2936L10.5858 12.0006L5.29279 6.70757C5.10532 6.52004 5 6.26573 5 6.00057C5 5.73541 5.10532 5.4811 5.29279 5.29357Z"
      fill="#333333"
    />
  </svg>
);
const CloseIcon = props => <Icon component={closeIconSvg} {...props} />;
export default CloseIcon;
