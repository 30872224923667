import React from 'react';

import Icon from '@ant-design/icons';

const collaborationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      d="M3.79911 18.9738C3.69189 19.0045 3.57842 19.006 3.47045 18.978C3.36248 18.9501 3.26396 18.8938 3.1851 18.8149C3.10624 18.736 3.04992 18.6375 3.02198 18.5295C2.99403 18.4216 2.99549 18.3081 3.02619 18.2009L3.91473 15.0894C3.05149 13.5057 2.78791 11.6639 3.17231 9.90165C3.5567 8.13937 4.56332 6.57465 6.00767 5.49427C7.45202 4.41388 9.23735 3.89021 11.0364 4.01922C12.8355 4.14823 14.5279 4.92129 15.8033 6.19671C17.0787 7.47212 17.8518 9.16446 17.9808 10.9636C18.1098 12.7627 17.5861 14.548 16.5057 15.9923C15.4253 17.4367 13.8606 18.4433 12.0983 18.8277C10.3361 19.2121 8.49429 18.9485 6.91056 18.0853L3.79911 18.9738Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 18.9846C10.3715 20.0103 10.9675 20.9454 11.747 21.725C12.5264 22.5047 13.4706 23.1105 14.5143 23.5005C15.558 23.8904 16.6764 24.0553 17.792 23.9837C18.9076 23.9121 19.994 23.6057 20.976 23.0856L24.1773 23.9748C24.2876 24.0056 24.4044 24.007 24.5155 23.9791C24.6266 23.9511 24.7279 23.8947 24.8091 23.8158C24.8902 23.7369 24.9482 23.6383 24.9769 23.5302C25.0057 23.4222 25.0042 23.3086 24.9726 23.2013L24.0584 20.0875C24.6821 18.9749 25.006 17.7267 24.9999 16.4594C24.9938 15.1921 24.6579 13.9469 24.0235 12.84C23.3891 11.733 22.4768 10.8003 21.3718 10.1289C20.2667 9.45744 19.0049 9.06907 17.7039 9"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const CollaborationIcon = props => <Icon component={collaborationIcon} {...props} />;
export default CollaborationIcon;
