import React from 'react';

import Icon from '@ant-design/icons';

const CancelNews = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8844 20.536L19.5767 14.8438"
        stroke="#FF4444"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8848 14.8438L19.577 20.536"
        stroke="#FF4444"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8615 8.34006H3.63846C3.01231 8.34006 2.5 8.88896 2.5 9.55984C2.5 10.2307 3.01231 10.7796 3.63846 10.7796H19.8615C20.4877 10.7796 21 10.2307 21 9.55984C21 8.88896 20.4877 8.34006 19.8615 8.34006ZM19.8615 3.46094H3.63846C3.01231 3.46094 2.5 4.00984 2.5 4.68072C2.5 5.3516 3.01231 5.9005 3.63846 5.9005H19.8615C20.4877 5.9005 21 5.3516 21 4.68072C21 4.00984 20.4877 3.46094 19.8615 3.46094ZM3.63846 15.6587H10.4692C11.0954 15.6587 11.6077 15.1098 11.6077 14.439C11.6077 13.7681 11.0954 13.2192 10.4692 13.2192H3.63846C3.01231 13.2192 2.5 13.7681 2.5 14.439C2.5 15.1098 3.01231 15.6587 3.63846 15.6587Z"
        fill="#FF4444"
      />
    </svg>
  );
};

const CancelNewsIcon = props => <Icon component={CancelNews} {...props} />;
export default CancelNewsIcon;
