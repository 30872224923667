import React from 'react';

import Icon from '@ant-design/icons';

const icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <g clip-path="url(#clip0_2054_19393)">
      <path
        d="M14 14.875C15.933 14.875 17.5 13.308 17.5 11.375C17.5 9.442 15.933 7.875 14 7.875C12.067 7.875 10.5 9.442 10.5 11.375C10.5 13.308 12.067 14.875 14 14.875Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.75 11.375C22.75 19.25 14 25.375 14 25.375C14 25.375 5.25 19.25 5.25 11.375C5.25 9.05436 6.17187 6.82876 7.81282 5.18782C9.45376 3.54687 11.6794 2.625 14 2.625C16.3206 2.625 18.5462 3.54687 20.1872 5.18782C21.8281 6.82876 22.75 9.05436 22.75 11.375Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2054_19393">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const FacilityIcon = props => <Icon component={icon} {...props} />;
