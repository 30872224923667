export default {
  'profile.update': 'Save changes',
  'profile.fullName': 'First and last name',
  'profile.userName': 'User name',
  'profile.phone': 'Phone number',
  'profile.internalComment': 'Note',
  'profile.brand': 'Brand',
  'profile.role': 'Role',
  'profile._password': 'Password',
  'profile.createDateTime': 'Date created',
  'profile.isActive': 'Operating status',
  'profile.change.password.title': 'Reset password',
  'profile.change.password.btn': 'Change password',
  'profile.change.password.subtitle': 'Please choose a new password to log in',
  'profile.newPassword': 'A new password',
  'profile.confirm.newPassword': 'Confirm the new password',
  'password.not.match': 'password incorrect',
  'profile.name': 'Profile',
  'profile.title': 'Personal information',
  'profile.oldPassword': 'Old password',
};
