import React from 'react';

import { UilSetting } from '@iconscout/react-unicons';
import { IRouter } from '@routers/interface';

export const routerUser: IRouter = {
  path: '/setting/user',
  loader: React.lazy(() => import('./Pages/User/index')),
  exact: true,
  name: 'user.management.breadcrumb', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerSettingOverviewVideo: IRouter = {
  path: '/setting/overview-video',
  loader: React.lazy(() => import('./Pages/SettingOverviewVideo/index')),
  exact: true,
  name: 'common.overview.video', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerSettingScreenSaverVideoSetting: IRouter = {
  path: '/setting/screen-saver',
  loader: React.lazy(() => import('./Pages/ScreenSaverVideoSetting/index')),
  exact: true,
  name: 'common.screen.saver', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerSetting: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'setting.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UilSetting />,
    activePath: '/settings',
  },
  routes: [routerUser, routerSettingOverviewVideo, routerSettingScreenSaverVideoSetting],
};
