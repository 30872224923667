import { PresetColorType } from 'antd/lib/_util/colors';

import { languageEN, languageVN } from '@assets/images';
import ISelect from '@core/select';

export const LANGUAGE: ISelect<string>[] = [
  { value: 'vi', label: 'VNM', icon: languageVN },
  { value: 'en', label: 'ENG', icon: languageEN },
];

export enum UEH_LANGUAGE {
  VN = 'vi',
  ENG = 'en',
}

export const allSelect: ISelect = { label: 'common.all', value: undefined };

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL,
  APP_NAME: process.env.APP_NAME || 'CMS-UEH',
  LOGIN_PAGE: process.env.SSO_PAGE,
  CLIENT_ID: process.env.CLIENT_ID,
  API_KEY_GG_MAP: process.env.API_KEY_GG_MAP || 'AIzaSyCUl_Bfc4ztoB9wrIOHST6iJtAORie66Hc',
};

export const colors: PresetColorType[] = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];

export default CONFIG;
