import { Button, Typography } from 'antd';
import React from 'react';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { imgAvatar } from '@assets/images';
import store from '@core/store/redux';
import { RootState } from '@modules';
import { removeProfile } from '@modules/authentication/profileStore';
import { DeleteConfirm } from '@shared/components/ConfirmDelete';
import SearchApiComponent, { SearchApi } from '@shared/components/SearchApiComponent';
import { useAltaIntl } from '@shared/hook/useTranslate';

interface IPropsHeaderComponent {
  searchApi?: SearchApi;
}

const HeaderComponent: React.FC<IPropsHeaderComponent> = props => {
  const { user } = useSelector((state: RootState) => state.profile);
  const navigate = useNavigate();
  const { formatMessage } = useAltaIntl();

  return (
    <div className="header-component intro-x">
      <div className="wrapper">
        <div className="wrap-avatar">
          <img
            alt="img-avatar"
            className="avatar"
            src={user?.avatarPicture || imgAvatar}
            onClick={() => navigate('/profile')}
          />
        </div>
        <div className="wrap_text">
          <Typography.Paragraph ellipsis={{ rows: 1, tooltip: true }} className="name">
            {user?.name || 'Unknown'}
          </Typography.Paragraph>
          <Button
            type="primary"
            className="btn"
            onClick={() => {
              DeleteConfirm({
                title: formatMessage('common.logout.title'),
                content: formatMessage('common.logout.content'),
                handleOk: () => {
                  store.dispatch(removeProfile());
                  navigate('/');
                },
              });
            }}
          >
            <span>{formatMessage('common.logout')}</span> <Icon.LogOut />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HeaderComponent);
