export default {
  'user.management': 'Người dùng',
  'user.management.breadcrumb': 'Quản lý người dùng',
  'users.name': 'Tên người dùng',
  'users.name.required.1': 'Vui lòng nhập tên người dùng',
  'users.name.required.2': 'Tên người dùng không đúng định dạng',
  'users.userName': 'Tên đăng nhập',
  'users.fullName': 'Họ và tên',
  'users.phoneNumber': 'Số điện thoại',
  'users.action': 'Hành động',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.password': 'Mật khẩu',
  'users.userType': 'Vai trò',
  'users.create': 'Tạo người dùng',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'users.citizenId': 'Số CMND/CCCD',
  'users.email': 'Email',
  'users.add': 'Thêm người dùng',
  'users.add.new': 'Thêm người dùng mới',

  'users.table.username': 'Tên đăng nhập',
  'users.table.name': 'Họ và tên',
  'users.table.type': 'Loại tài khoản',
  'users.table.role': 'Vai trò',
  'users.table.phoneNumber': 'Số điện thoại',
  'users.table.email': 'Email',
  'users.table.action': 'Hành động',

  /////// User
  'user.name': 'Người dùng',
  'users.displayName': 'Múi giờ',
  'user.list': 'Danh sách người dùng',
  'user.username': 'Tên đăng nhập',
  'user.userFullName': 'Họ và tên',
  'user.email': 'Email',
  'user.userPhone': 'Số điện thoại',
  'user.userAvatar': 'Hình đại diện',
  'user.userPassword': 'Mật khẩu',
  'users.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'users.modal.form.roleId': 'Vai trò',
  'user.isActive': 'Trạng thái hoạt động',
  'users.modal.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'users.modal.form.confirmPassWord.placeholder': 'Vui lòng nhập xác nhận mật khẩu',
  'users.modal.form.confirmPassWord.required': 'Hai mật khẩu bạn đã nhập không khớp',
  'user.validator-userName': 'Tên đăng nhập không được để trống',
  'user.validator-userFullName': 'Vui lòng nhập họ và tên',

  'users.isActive.id': '{status, select, 1 {Hoạt động} 0 {Ngưng hoạt động} other {--} }',
  'users.validate-password': 'Mật khẩu không đúng định dạng',
  'users.validate-phone': 'Số điện thoại không đúng định dạng',

  'users.status': 'Trạng thái',
  'users.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',

  'settings.name': 'Cài đặt hệ thống',
  'users.title': 'Danh sách người dùng đã phân quyền',
  'users.tab.name': 'Người dùng',
  'user.filter.status': 'Trạng thái',
  'user.status.active': 'Đang hoạt động',
  'user.status.notActive': 'Ngưng hoạt động',
  'roles.placeholder.name': 'Vui lòng chọn vai trò',
  'userList.role.name': 'Vai trò',
  'users.havePermission': 'Đã phân quyền',
  'users.noPermission': 'Chưa phân quyền',
  'users.noPermission.title': 'Danh sách người dùng chưa phân quyền',
  'users.delete.title': 'Xác nhận xóa người dùng',
  'users.delete.content': 'Người dùng này sẽ xóa khỏi hệ thống',
  'users.identifierNumber': 'Số CMND/CCCD',

  'users.validator-userName': 'Vui lòng nhập tên đăng nhập',
  'users.validatorUserName': 'Tên đăng nhập không đúng định dạng',
  'users.title.endUser': 'Danh sách người dùng hệ thống',
  //facilitiesUsers
  'facilitiesUsers.role.name': 'Vai trò',
  'facilitiesUsers.name': 'Quản lý người dùng',
  'facilitiesUsers.title': 'Tài khoản quản trị cơ sở',
  'facilitiesUsers.create': 'Tạo người dùng',
  'facilitiesUsers.update': 'Cập nhật người dùng',
  'facility.tabs.user': 'Quản lý người dùng',
  'facilitiesUsers.delete.title': 'Xác nhận xóa tài khoản',
  'facilitiesUsers.delete.content':
    'Các tài khoản quản trị cơ sở được chọn sẽ bị xóa và không thể khôi phục.',
  'user.validatorUndefined': 'Họ và tên không đúng định dạng',
  'user.validator-undefined': 'Vui lòng nhập tên đăng nhập',
  'user.validatorUserName': 'Tên đăng nhập không đúng định dạng',
  'users.lastAccessedAt': 'Truy cập lần cuối',
  'user.lastAccessedAt': 'Truy cập lần cuối',

  'users.modal.form.password': 'Mật khẩu',
  'settings.router.manager': 'Quản lý cài đặt hệ thống',
  'user.timezoneId': 'Múi giờ',
  'users.validator-phoneNumber': 'Vui lòng nhập số điện thoại',
  'users.validator-email': 'Vui lòng nhập email',
  'users.validator-roleId': 'Vui lòng nhập vai trò',
  'users.validator-status': 'Vui lòng nhập trạng thái hoạt động',
  'users.validator-timezoneId': 'Vui lòng nhập múi giờ',
};
