import locale, { Locale } from '@locale/index';
import { useIntl } from 'react-intl';

export const useAltaIntl = () => {
  const intl = useIntl();
  const language: keyof Locale = intl.locale as keyof Locale;

  const formatMessage = (key: string): string => {
    return intl.formatMessage({ id: key });
  };
  const formatCurrency = (v: number) => {
    return intl.formatNumber(v, {
      style: 'currency',
      currency: 'vnd',
      unitDisplay: 'narrow',
      notation: 'standard',
    });
  };

  const translateString = (title: string) => {
    const regex = new RegExp(Object.keys(locale[language]).join('|'), 'g');
    return title.replace(regex, match => locale[language][match]);
  };

  return {
    intl,
    formatMessage,
    formatCurrency,
    translateString,
  };
};
