export default {
  'team.name': 'Đội ngũ',
  'team.member.status': '{status, select, 1 {Bình thường} 2 {Đã mất} other {--}}',
  'team.overview': 'Tổng quan',
  'team.overview.title': 'Danh sách số lượng đội ngũ theo năm',
  'team.overview.year': 'Năm',
  'team.overview.year.required': 'Năm phải là số nguyên',
  'team.overview.year.placeholder': 'Vui lòng nhập năm',
  'team.overview.year.add': 'Thêm năm mới',
  'team.overview.year.update': 'Cập nhật năm',
  'team.overview.year.info': 'Thông tin năm',
  'team.overview.professor': 'Giáo sư',
  'team.overview.associateProfessor': 'PGS',
  'team.overview.doctor': 'Tiến sĩ',
  'team.overview.master': 'Thạc sĩ',
  'team.overview.bachelor': 'Đại học',
  'team.overview.other': 'Khác',
  'team.overview.action': 'Hành động',
  'team.overview.confirm.delete.title': 'Xác nhận xóa năm',
  'team.overview.confirm.delete.content': 'Năm này sẽ xóa khỏi hệ thống',
  'team.overview.label.professor': 'Số giáo sư',
  'team.overview.label.associateProfessor': 'Số PGS',
  'team.overview.label.doctor': 'Số tiến sĩ',
  'team.overview.label.master': 'Số thạc sĩ',
  'team.overview.label.bachelor': 'Số đại học',
  'team.overview.label.other': 'Số khác',

  'team.overview.required.professor': 'Số giáo sư phải là số nguyên',
  'team.overview.required.associateProfessor': 'Số PGS phải là số nguyên',
  'team.overview.required.doctor': 'Số tiến sĩ phải là số nguyên',
  'team.overview.required.master': 'Số thạc sĩ phải là số nguyên',
  'team.overview.required.bachelor': 'Số đại học phải là số nguyên',
  'team.overview.required.other': 'Số khác phải là số nguyên',

  'team.overview.placeholder.professor': 'Vui lòng nhập số giáo sư',
  'team.overview.placeholder.associateProfessor': 'Vui lòng nhập số PGS',
  'team.overview.placeholder.doctor': 'Vui lòng nhập số tiến sĩ',
  'team.overview.placeholder.master': 'Vui lòng nhập số thạc sĩ',
  'team.overview.placeholder.bachelor': 'Vui lòng nhập số đại học',
  'team.overview.placeholder.other': 'Vui lòng nhập số khác',

  'team.outstanding': 'Đội ngũ tiêu biểu',
  'team.outstanding.appear': 'Hiện',
  'team.outstanding.hide': 'Ẩn',
  'team.outstanding.page': 'Trang',
  'team.outstanding.title': 'Danh sách đội ngũ tiêu biểu',
  'team.outstanding.add.type': 'Thêm loại đội ngũ',
  'team.outstanding.update.type': 'Cập nhật loại đội ngũ',
  'team.outstanding.info.type': 'Thông tin loại đội ngũ',
  'team.outstanding.add.type.new': 'Thêm loại đội ngũ mới',
  'team.outstanding.delete.title': 'Xác nhận loại đội ngũ',
  'team.outstanding.delete.content': 'Loại đội ngũ này sẽ xóa khỏi hệ thống',
  'team.outstanding.delete.member.title': 'Xác nhận xóa thành viên',
  'team.outstanding.delete.member.content': 'Thành viên này sẽ xóa khỏi hệ thống',

  'team.outstanding.member.status.placeholder': 'Vui lòng nhập trạng thái',
  'team.outstanding.member.vitalStatus.1': 'Bình thường',
  'team.outstanding.member.vitalStatus.2': 'Đã mất',
  'team.outstanding.member.recognitionYear': 'Năm phong',
  'team.outstanding.member.recognitionYear.required': 'Năm phong phải là số nguyên',
  'team.outstanding.member.recognitionYear.placeholder': 'Vui lòng nhập số năm phong',
  'team.outstanding.member.info': 'Thông tin thành viên',
  'team.outstanding.member.update': 'Cập nhật thành viên',
  'team.outstanding.member.add': 'Thêm thành viên mới',
};
