import dayjs from 'dayjs';

import { UEH_LANGUAGE } from '@config';

export enum ETypeAchievements {
  Ranking = 1,
  AchievementUEH = 2,
}

export interface Translation {
  languageCode: string;
  picture?: string;
  title?: string;
  content?: string;
  createdAt?: string;
}

export interface ETypeAchIndAColls {
  pageSize: number;
  display: boolean;
  id: string;
  createdAt: string;
  translations?: Translation[];
}

class AchievementsEntity {
  //copy props from backend:
  createdAt = '';

  type?: ETypeAchievements;

  translations?: Translation[];

  id: string = '';

  display?: boolean;

  key: string = '';

  pictureVn?: string = '';

  titleVn?: string = '';

  contentVn?: string = '';

  pictureEng?: string = '';

  titleEng?: string = '';

  contentEng?: string = '';

  pageSize?: number;

  value: string = '';

  label: string = '';

  description: string = '';

  sortOrder = 0;

  constructor(achievements: Partial<AchievementsEntity>) {
    if (!achievements) {
      return;
    }
    Object.assign(this, achievements);
    // convert entity type here
    this.createdAt = achievements.createdAt
      ? dayjs(achievements.createdAt).format('DD/MM/YYYY')
      : '';
    this.key = achievements.id || '';

    this.pictureVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.picture;
    this.titleVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.title;
    this.contentVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.content;

    this.pictureEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.picture;
    this.titleEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.title;
    this.contentEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.content;
    this.value = achievements.id || '';
    this.label = `${this.titleVn} - ${this.titleEng}`;
    this.description = `${
      (achievements.type as any).translations?.find(it => it.languageCode === UEH_LANGUAGE.VN)
        ?.title || ''
    } - ${
      (achievements.type as any).translations?.find(it => it.languageCode === UEH_LANGUAGE.ENG)
        ?.title || ''
    }`;
  }

  static createListAchievements(listAchievements: Array<Partial<AchievementsEntity>>) {
    if (!Array.isArray(listAchievements)) {
      return [];
    }
    return listAchievements.map((achievements: Partial<AchievementsEntity>) => {
      return new AchievementsEntity(achievements);
    });
  }
}
export default AchievementsEntity;

export class AchIndACollsEntity {
  //copy props from backend:
  createdAt = '';

  type?: ETypeAchIndAColls;

  translations?: Translation[];

  id: string = '';

  key: string = '';

  pictureVn?: string = '';

  titleVn?: string = '';

  contentVn?: string = '';

  pictureEng?: string = '';

  titleEng?: string = '';

  contentEng?: string = '';

  pageSize?: number;

  constructor(achievements: Partial<AchIndACollsEntity>) {
    if (!achievements) {
      return;
    }
    Object.assign(this, achievements);
    // convert entity type here
    this.createdAt = achievements.createdAt
      ? dayjs(achievements.createdAt).format('DD/MM/YYYY')
      : '';

    this.key = achievements.id || '';

    this.pictureVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.picture;
    this.titleVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.title;
    this.contentVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.content;

    this.pictureEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.picture;
    this.titleEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.title;
    this.contentEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.content;
  }

  static createListAchievements(listAchievements: Array<Partial<AchIndACollsEntity>>) {
    if (!Array.isArray(listAchievements)) {
      return [];
    }
    return listAchievements.map((achievements: Partial<AchIndACollsEntity>) => {
      return new AchIndACollsEntity(achievements);
    });
  }
}

export interface TranslationCollType {
  languageCode: string;
  title?: string;
  content?: string;
  createdAt?: string;
  id: string;
}

export class AchIndACollsTypeEntity {
  //copy props from backend:
  createdAt = '';

  type?: ETypeAchIndAColls;

  translations?: TranslationCollType[];

  id: string = '';

  key: string = '';

  titleVn?: string = '';

  contentVn?: string = '';

  titleEng?: string = '';

  contentEng?: string = '';

  pageSize?: number;

  display?: boolean;

  constructor(achievements: Partial<AchIndACollsTypeEntity>) {
    if (!achievements) {
      return;
    }
    Object.assign(this, achievements);
    // convert entity type here
    this.createdAt = achievements.createdAt
      ? dayjs(achievements.createdAt).format('DD/MM/YYYY')
      : '';

    this.key = achievements.id || '';

    this.titleVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.title;
    this.contentVn = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.VN,
    )?.content;

    this.titleEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.title;
    this.contentEng = achievements.translations?.find(
      it => it.languageCode === UEH_LANGUAGE.ENG,
    )?.content;
  }

  static createListAchievements(listAchievements: Array<Partial<AchIndACollsTypeEntity>>) {
    if (!Array.isArray(listAchievements)) {
      return [];
    }
    return listAchievements.map((achievements: Partial<AchIndACollsTypeEntity>) => {
      return new AchIndACollsTypeEntity(achievements);
    });
  }
}
