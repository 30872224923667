import React from 'react';

import Icon from '@ant-design/icons';

const alumniIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      d="M1.16602 9.33333L13.9993 3.5L26.8327 9.33333L13.9993 15.1667L1.16602 9.33333Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M26.8333 10.5V16.5085M7 13.3111V21.4166C7 21.4166 9.93642 24.5 13.9998 24.5C18.0638 24.5 21.0002 21.4166 21.0002 21.4166V13.3111"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const AlumniIcon = props => <Icon component={alumniIcon} {...props} />;
export default AlumniIcon;
