export default {
  'collaboration.name': 'Hợp tác',
  'collaboration.tab.professionalAssociation': 'Hiệp hội nghề nghiệp',

  //InternationalEducationInstitution
  'collaboration.tab.interEduIns': 'Tổ chức giáo dục quốc tế',
  'interEduIns.title.region': 'Danh sách lục địa',
  'interEduIns.title.country': 'Danh sách quốc gia',
  'interEduIns.country': 'Lục địa',
  'interEduIns.country.quantity': 'Số quốc gia',
  'interEduIns.table.country': 'Thông tin',
  'interEduIns.table.picture': 'Hình ảnh',
  'interEduIns.action': 'Hành động',
  'interEduIns.add': 'Thêm',
  'interEduIns.pageSize': 'Số thư mục',
  'interEduIns.modal.title': 'Bố cục',
  'interEduIns.modal.page': 'trang',
  'interEduIns.modal.layout': 'Xem trước bố cục',
  'interEduIns.delete.title': 'Xác nhận xóa quốc gia ',
  'interEduIns.delete.content': 'Quốc gia này sẽ xóa khỏi hệ thống',
  'form.title.country': 'Quốc gia',
  'collaboration.name.label.1': 'Hiệp hội nghề nghiệp',
  'collaboration.name.label.2': 'Doanh nghiệp',
  'collaboration.name.label.3': 'Cơ quan nhà nước',
  'collaboration.name.label.4': 'Tổ chức giáo dục trong nước',
  'collaboration.name.title.1': 'Hình ảnh Hiệp hội nghề nghiệp',
  'collaboration.name.title.2': 'Hình ảnh Doanh nghiệp',
  'collaboration.name.title.3': 'Hình ảnh Cơ quan nhà nước',
  'collaboration.name.title.4': 'Hình ảnh Tổ chức giáo dục trong nước',
  'collaboration.modal.country': 'Vui lòng chọn quốc gia',
};
