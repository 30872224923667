import React from 'react';

import Icon from '@ant-design/icons';

const editProfileIconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_1608_26237)">
      <path
        d="M10.084 3.66602H3.66732C3.18109 3.66602 2.71477 3.85917 2.37096 4.20299C2.02714 4.5468 1.83398 5.01312 1.83398 5.49935V18.3327C1.83398 18.8189 2.02714 19.2852 2.37096 19.629C2.71477 19.9729 3.18109 20.166 3.66732 20.166H16.5007C16.9869 20.166 17.4532 19.9729 17.797 19.629C18.1408 19.2852 18.334 18.8189 18.334 18.3327V11.916"
        stroke="#DD6D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.959 2.2922C17.3237 1.92753 17.8183 1.72266 18.334 1.72266C18.8497 1.72266 19.3443 1.92753 19.709 2.2922C20.0737 2.65687 20.2785 3.15147 20.2785 3.6672C20.2785 4.18292 20.0737 4.67753 19.709 5.0422L11.0007 13.7505L7.33398 14.6672L8.25065 11.0005L16.959 2.2922Z"
        stroke="#DD6D38"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1608_26237">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const EditProfileIcon = props => <Icon component={editProfileIconSvg} {...props} />;
export default EditProfileIcon;
