export const logo = require('./logo.png');
export const logoAuth = require('./logo_auth.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconDot = require('./dotIcon.png');
export const mapPin = require('./mapPin.svg');
export const img404 = require('./bg_404.png');
export const locationBigIcon = require('./dashboard_location_big_icon.png');
export const locationSmallIcon = require('./dashboard_location_small_icon.png');
export const deviceBigIcon = require('./dashboard_device_big_icon.png');
export const deviceSmallIcon = require('./dashboard_device_small_icon.png');
export const newsBigIcon = require('./dashboard_news_big_icon.png');
export const newsSmallIcon = require('./dashboard_news_small_icon.png');
export const organizeBigIcon = require('./dashboard_organize_big_icon.png');
export const organizeSmallIcon = require('./dashboard_organize_small_icon.png');
export const capacityBigIcon = require('./dashboard_capacity_big_icon.png');
export { default as ArrowLeftImg } from './arrow-left.png';
export { default as LogoutIcon } from './LogoutIcon.png';
export { default as audioThumbnail } from './audio-thumbnail.png';
export { default as defaultMedia } from './audio-thumbnail.png';
export { default as iconNewsDetailTable } from './news_detail_table_icon_img.png';
export { default as modalRefuseIcon } from './modal_refuse_icon_img.png';
export { default as deviceRed } from './dashboard_icon_red.png';
export { default as deviceGreen } from './dashboard_icon_green.png';
export { default as uploadIconImg } from './upload_icon_img.png';
export { default as ForwardsIconImg } from './ForwardsIcon.png';
export { default as BackWardsIconImg } from './BackWardsIcon.png';
export { default as fileError } from './file-error.png';

export const layoutChart = require('./layout_chart.png');
export const layoutChart10 = require('./layout_chart10.png');
export const AchIndAColls1 = require('./AchIndAColls1.png');
export const AchIndAColls2 = require('./AchIndAColls2.png');
export const AchIndAColls3 = require('./AchIndAColls3.png');
export const AchIndAColls4 = require('./AchIndAColls4.png');
export const AchIndAColls5 = require('./AchIndAColls5.png');
export const AchIndAColls6 = require('./AchIndAColls6.png');
export const AchIndAColls7 = require('./AchIndAColls7.png');
export const AchIndAColls8 = require('./AchIndAColls8.png');
export const AchIndAColls9 = require('./AchIndAColls9.png');
export const AchIndAColls10 = require('./AchIndAColls10.png');
export const AchIndAColls11 = require('./AchIndAColls11.png');
export const AchIndAColls12 = require('./AchIndAColls12.png');
export const AchIndAColls13 = require('./AchIndAColls13.png');
export const AchIndAColls14 = require('./AchIndAColls14.png');
export const AchIndAColls15 = require('./AchIndAColls15.png');
export const AchIndAColls16 = require('./AchIndAColls16.png');
export const AchIndAColls17 = require('./AchIndAColls17.png');
export const AchIndAColls18 = require('./AchIndAColls18.png');
export const AchIndAColls19 = require('./AchIndAColls19.png');
export const AchIndAColls20 = require('./AchIndAColls20.png');
export const AchIndAColls21 = require('./AchIndAColls21.png');
export const AchIndAColls22 = require('./AchIndAColls22.png');

export { default as UpIconImg } from './UpIconImg.png';
export { default as DownIconImg } from './DownIconImg.png';

export { default as TeamLayout1 } from './TeamLayout1.png';
export { default as TeamLayout2 } from './TeamLayout2.png';
export { default as TeamLayout3 } from './TeamLayout3.png';
export { default as TeamLayout4 } from './TeamLayout4.png';
export { default as TeamLayout5 } from './TeamLayout5.png';
export { default as TeamLayout6 } from './TeamLayout6.png';
export { default as TeamLayout7 } from './TeamLayout7.png';
export { default as TeamLayout8 } from './TeamLayout8.png';
export { default as TeamLayout9 } from './TeamLayout9.png';
export { default as TeamLayout10 } from './TeamLayout10.png';
export { default as TeamLayout11 } from './TeamLayout11.png';
export { default as TeamLayout12 } from './TeamLayout12.png';
export { default as TeamLayout13 } from './TeamLayout13.png';
export { default as TeamLayout14 } from './TeamLayout14.png';
export { default as TeamLayout15 } from './TeamLayout15.png';
export { default as TeamLayout16 } from './TeamLayout16.png';
export { default as TeamLayout17 } from './TeamLayout17.png';
export { default as TeamLayout18 } from './TeamLayout18.png';
export { default as TeamLayout19 } from './TeamLayout19.png';
export { default as TeamLayout20 } from './TeamLayout20.png';
export { default as TeamLayout21 } from './TeamLayout21.png';
export { default as TeamLayout22 } from './TeamLayout22.png';
export { default as layoutOrganization1 } from './layout-organization-1.png';
export { default as layoutOrganization2 } from './layout-organization-2.png';
export { default as layoutOrganization3 } from './layout-organization-3.png';
export { default as layoutOrganization4 } from './layout-organization-4.png';

export { default as socialOrg1 } from './socialOrg-1.png';
export { default as socialOrg2 } from './socialOrg-2.png';
export { default as socialOrg3 } from './socialOrg-3.png';
export { default as socialOrg4 } from './socialOrg-4.png';
export { default as socialOrg5 } from './socialOrg-5.png';
export { default as socialOrg6 } from './socialOrg-6.png';

export { default as layoutLd1 } from './layout-ld1.png';
export { default as layoutLd2 } from './layout-ld2.png';
export { default as layoutLd3 } from './layout-ld3.png';
export { default as layoutLd4 } from './layout-ld4.png';
export { default as layoutLd5 } from './layout-ld5.png';
export { default as layoutLd6 } from './layout-ld6.png';
export { default as layoutLd7 } from './layout-ld7.png';
export { default as layoutLd8 } from './layout-ld8.png';

export { default as Ach1 } from './Ach-1.png';
export { default as Ach2 } from './Ach-2.png';
export { default as Ach3 } from './Ach-3.png';
export { default as Ach4 } from './Ach-4.png';
export { default as Ach5 } from './Ach-5.png';
export { default as Ach6 } from './Ach-6.png';
export { default as Ach7 } from './Ach-7.png';
export { default as Ach8 } from './Ach-8.png';
export { default as Ach9 } from './Ach-9.png';
export { default as Ach10 } from './Ach-10.png';
export { default as Ach11 } from './Ach-11.png';
export { default as Ach12 } from './Ach-12.png';
export { default as Ach13 } from './Ach-13.png';
export { default as Ach14 } from './Ach-14.png';
export { default as Ach15 } from './Ach-15.png';
export { default as Ach16 } from './Ach-16.png';
export { default as Ach17 } from './Ach-17.png';
export { default as Ach18 } from './Ach-18.png';
export { default as Ach19 } from './Ach-19.png';
export { default as Ach20 } from './Ach-20.png';
export { default as Ach21 } from './Ach-21.png';
export { default as Ach22 } from './Ach-22.png';

export { default as AlumniLayout1 } from './AlumniLayout1.png';
export { default as AlumniLayout2 } from './AlumniLayout2.png';
export { default as AlumniLayout3 } from './AlumniLayout3.png';
export { default as AlumniLayout4 } from './AlumniLayout4.png';
export { default as AlumniLayout5 } from './AlumniLayout5.png';
export { default as AlumniLayout6 } from './AlumniLayout6.png';
export { default as AlumniLayout7 } from './AlumniLayout7.png';
export { default as AlumniLayout8 } from './AlumniLayout8.png';
export { default as AlumniLayout9 } from './AlumniLayout9.png';
export { default as AlumniLayout10 } from './AlumniLayout10.png';
export { default as AlumniLayout11 } from './AlumniLayout11.png';
export { default as AlumniLayout12 } from './AlumniLayout12.png';
export { default as AlumniLayout13 } from './AlumniLayout13.png';
export { default as AlumniLayout14 } from './AlumniLayout14.png';
export { default as AlumniLayout15 } from './AlumniLayout15.png';
export { default as AlumniLayout16 } from './AlumniLayout16.png';
export { default as AlumniLayout17 } from './AlumniLayout17.png';
export { default as AlumniLayout18 } from './AlumniLayout18.png';
export { default as AlumniLayout19 } from './AlumniLayout19.png';
export { default as AlumniLayout20 } from './AlumniLayout20.png';
export { default as HoChiMinh } from './Ho-Chi-Minh.png';
export { default as LocationIcon } from './LocationIcon.png';
export { default as InterEduIns1 } from './InterEduIns1.png';
export { default as InterEduIns2 } from './InterEduIns2.png';
export { default as InterEduIns3 } from './InterEduIns3.png';
export { default as InterEduIns4 } from './InterEduIns4.png';
export { default as InterEduIns5 } from './InterEduIns5.png';
export { default as InterEduIns6 } from './InterEduIns6.png';
export { default as InterEduIns7 } from './InterEduIns7.png';
export { default as InterEduIns8 } from './InterEduIns8.png';
export { default as InterEduIns9 } from './InterEduIns9.png';
export { default as InterEduIns10 } from './InterEduIns10.png';
export { default as InterEduIns11 } from './InterEduIns11.png';
export { default as InterEduIns12 } from './InterEduIns12.png';
export { default as InterEduIns13 } from './InterEduIns13.png';
export { default as InterEduIns14 } from './InterEduIns14.png';
export { default as InterEduIns15 } from './InterEduIns15.png';
export { default as InterEduIns16 } from './InterEduIns16.png';
export { default as InterEduIns17 } from './InterEduIns17.png';
export { default as InterEduIns18 } from './InterEduIns18.png';
export { default as InterEduIns19 } from './InterEduIns19.png';
export { default as InterEduIns20 } from './InterEduIns20.png';
export { default as InterEduIns21 } from './InterEduIns21.png';
export { default as InterEduIns22 } from './InterEduIns22.png';
export { default as InterEduIns23 } from './InterEduIns23.png';
export { default as InterEduIns24 } from './InterEduIns24.png';
export { default as InterEduIns25 } from './InterEduIns25.png';
export { default as InterEduIns26 } from './InterEduIns26.png';
export { default as InterEduIns27 } from './InterEduIns27.png';
export { default as InterEduIns28 } from './InterEduIns28.png';
export { default as InterEduIns29 } from './InterEduIns29.png';
export { default as InterEduIns30 } from './InterEduIns30.png';
export { default as InterEduIns31 } from './InterEduIns31.png';
export { default as InterEduIns32 } from './InterEduIns32.png';
export { default as InterEduIns33 } from './InterEduIns33.png';
export { default as InterEduIns34 } from './InterEduIns34.png';
export { default as CollaborationLayout1 } from './CollaborationLayout1.png';
export { default as CollaborationLayout2 } from './CollaborationLayout2.png';
export { default as CollaborationLayout3 } from './CollaborationLayout3.png';
export { default as CollaborationLayout4 } from './CollaborationLayout4.png';
export { default as CollaborationLayout5 } from './CollaborationLayout5.png';
export { default as CollaborationLayout6 } from './CollaborationLayout6.png';
export { default as CollaborationLayout7 } from './CollaborationLayout7.png';
export { default as CollaborationLayout8 } from './CollaborationLayout8.png';
export { default as CollaborationLayout9 } from './CollaborationLayout9.png';
export { default as CollaborationLayout10 } from './CollaborationLayout10.png';
export { default as CollaborationLayout11 } from './CollaborationLayout11.png';
export { default as CollaborationLayout12 } from './CollaborationLayout12.png';
export { default as CollaborationLayout13 } from './CollaborationLayout13.png';
export { default as CollaborationLayout14 } from './CollaborationLayout14.png';
export { default as CollaborationLayout15 } from './CollaborationLayout15.png';
export { default as CollaborationLayout16 } from './CollaborationLayout16.png';
export { default as CollaborationLayout17 } from './CollaborationLayout17.png';
export { default as CollaborationLayout18 } from './CollaborationLayout18.png';
export { default as CollaborationLayout19 } from './CollaborationLayout19.png';
export { default as CollaborationLayout20 } from './CollaborationLayout20.png';
export { default as CollaborationLayout21 } from './CollaborationLayout21.png';
export { default as CollaborationLayout22 } from './CollaborationLayout22.png';
export { default as CollaborationLayout23 } from './CollaborationLayout23.png';
export { default as CollaborationLayout24 } from './CollaborationLayout24.png';
export { default as CollaborationLayout25 } from './CollaborationLayout25.png';
export { default as CollaborationLayout26 } from './CollaborationLayout26.png';
export { default as CollaborationLayout27 } from './CollaborationLayout27.png';
export { default as CollaborationLayout28 } from './CollaborationLayout28.png';
export { default as CollaborationLayout29 } from './CollaborationLayout29.png';
export { default as CollaborationLayout30 } from './CollaborationLayout30.png';
export { default as CollaborationLayout31 } from './CollaborationLayout31.png';
export { default as CollaborationLayout32 } from './CollaborationLayout32.png';
export { default as CollaborationLayout33 } from './CollaborationLayout33.png';
export { default as CollaborationLayout34 } from './CollaborationLayout34.png';

export { default as ActivityPictureLayout1 } from './ActivityPictureLayout1.png';
export { default as ActivityPictureLayout2 } from './ActivityPictureLayout2.png';
export { default as ActivityPictureLayout3 } from './ActivityPictureLayout3.png';
export { default as ActivityPictureLayout4 } from './ActivityPictureLayout4.png';
export { default as ActivityPictureLayout5 } from './ActivityPictureLayout5.png';
export { default as ActivityPictureLayout6 } from './ActivityPictureLayout6.png';

export { default as AchievementUEH1 } from './AchievementUEH1.png';
export { default as AchievementUEH2 } from './AchievementUEH2.png';
export { default as AchievementUEH3 } from './AchievementUEH3.png';
export { default as AchievementUEH4 } from './AchievementUEH4.png';
export { default as AchievementUEH5 } from './AchievementUEH5.png';
export { default as AchievementUEH6 } from './AchievementUEH6.png';
export { default as AchievementUEH7 } from './AchievementUEH7.png';
export { default as AchievementUEH8 } from './AchievementUEH8.png';
export { default as AchievementUEH9 } from './AchievementUEH9.png';
export { default as AchievementUEH10 } from './AchievementUEH10.png';
export { default as AchievementUEH11 } from './AchievementUEH11.png';
export { default as AchievementUEH12 } from './AchievementUEH12.png';
export { default as AchievementUEH13 } from './AchievementUEH13.png';
export { default as AchievementUEH14 } from './AchievementUEH14.png';
export { default as AchievementUEH15 } from './AchievementUEH15.png';
export { default as AchievementUEH16 } from './AchievementUEH16.png';
export { default as AchievementUEH17 } from './AchievementUEH17.png';
export { default as AchievementUEH18 } from './AchievementUEH18.png';
export { default as AchievementUEH19 } from './AchievementUEH19.png';
export { default as AchievementUEH20 } from './AchievementUEH20.png';
export { default as AchievementUEH21 } from './AchievementUEH21.png';
export { default as AchievementUEH22 } from './AchievementUEH22.png';
export { default as AchievementUEH23 } from './AchievementUEH23.png';
export { default as AchievementUEH24 } from './AchievementUEH24.png';
export { default as AchievementUEH25 } from './AchievementUEH25.png';
