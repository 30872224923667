import React from 'react';

import Icon from '@ant-design/icons';

const forwardsIcon = () => (
  <svg width="72" height="69" viewBox="0 0 72 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_545_2779)">
      <g clip-path="url(#clip0_545_2779)">
        <path
          d="M27 29.25H29.1619C30.1169 29.25 31.0582 29.4779 31.9074 29.9149C32.7566 30.3519 33.4892 30.9853 34.0444 31.7625L37.9556 37.2375C38.5108 38.0147 39.2434 38.6481 40.0926 39.0851C40.9418 39.5221 41.8831 39.75 42.8381 39.75H45.75"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.5 27L45.75 29.25L43.5 31.5"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.5 37.5L45.75 39.75L43.5 42"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.8438 31.9191L37.9562 31.7625C38.5114 30.9853 39.244 30.3519 40.0932 29.9149C40.9424 29.4779 41.8837 29.25 42.8387 29.25H45.7506"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27 39.7491H29.1619C30.1169 39.7492 31.0582 39.5212 31.9074 39.0842C32.7566 38.6472 33.4892 38.0138 34.0444 37.2366L34.1569 37.0801"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <rect
        x="6.5"
        y="7"
        width="59"
        height="55"
        rx="7.5"
        stroke="#005D69"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_545_2779"
        x="0"
        y="0.5"
        width="72"
        height="68"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.906458 0 0 0 0 0.915167 0 0 0 0 0.95 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_545_2779" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_545_2779"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_545_2779">
        <rect width="24" height="24" fill="white" transform="translate(24 22.5)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardsIcon = props => <Icon component={forwardsIcon} {...props} />;
export default ForwardsIcon;
