/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';

export enum EUserStatus {
  Active = 1,
  Lock = 2,
}

class UserEntity {
  username: string = '';

  name: string = '';

  email: string = '';

  status?: EUserStatus;

  avatarPicture?: string = '';

  id: string = '';

  createdAt: string = '';

  value: string = '';

  label: string = '';

  description: string = '';

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.createdAt = user?.createdAt ? dayjs(user?.createdAt).format('DD/MM/YYYY') : '';
    this.value = user?.id || '';
    this.label = user?.name || '';
    this.description = user?.email || '';
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
