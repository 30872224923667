import React from 'react';

import Icon from '@ant-design/icons';

const Continue = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.475 9.46429H19.8375C20.1344 9.46429 20.4375 9.73773 20.4375 10.1429C20.4375 10.548 20.1344 10.8214 19.8375 10.8214H3.475C3.17807 10.8214 2.875 10.548 2.875 10.1429C2.875 9.73773 3.17807 9.46429 3.475 9.46429ZM3.475 4.75H19.8375C20.1344 4.75 20.4375 5.02345 20.4375 5.42857C20.4375 5.83369 20.1344 6.10714 19.8375 6.10714L3.475 6.10714C3.17807 6.10714 2.875 5.83369 2.875 5.42857C2.875 5.02345 3.17807 4.75 3.475 4.75ZM11.5875 15.5357H3.475C3.17807 15.5357 2.875 15.2623 2.875 14.8571C2.875 14.452 3.17807 14.1786 3.475 14.1786H11.5875C11.8844 14.1786 12.1875 14.452 12.1875 14.8571C12.1875 15.2623 11.8844 15.5357 11.5875 15.5357Z"
        fill="#F26D21"
        stroke="#F26D21"
      />
      <path
        d="M13.8335 20.8774V14.2162C13.8338 14.0763 13.8719 13.9388 13.9439 13.8176C14.0159 13.6964 14.1193 13.5958 14.2438 13.5256C14.3684 13.4555 14.5096 13.4184 14.6535 13.418C14.7974 13.4176 14.9389 13.4539 15.0638 13.5233L20.7783 16.8618C21.2963 17.1767 21.2963 17.9169 20.7783 18.2161L15.0638 21.5545C14.5134 21.8695 13.8335 21.4915 13.8335 20.8774Z"
        fill="#F26D21"
      />
    </svg>
  );
};

const ContinueIcon = props => <Icon component={Continue} {...props} />;
export default ContinueIcon;
