import React from 'react';

import Icon from '@ant-design/icons';

const backwardsIcon = () => (
  <svg width="72" height="69" viewBox="0 0 72 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_434_58697)">
      <g clip-path="url(#clip0_434_58697)">
        <path
          d="M45 39.75L42.8381 39.75C41.8831 39.75 40.9418 39.5221 40.0926 39.0851C39.2434 38.6481 38.5108 38.0147 37.9556 37.2375L34.0444 31.7625C33.4892 30.9853 32.7566 30.3519 31.9074 29.9149C31.0582 29.4779 30.1169 29.25 29.1619 29.25L26.25 29.25"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.5 42L26.25 39.75L28.5 37.5"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28.5 31.5L26.25 29.25L28.5 27"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.1563 37.0809L34.0438 37.2375C33.4886 38.0147 32.756 38.6481 31.9068 39.0851C31.0576 39.5221 30.1163 39.75 29.1613 39.75L26.2494 39.75"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M45 29.2509L42.8381 29.2509C41.8831 29.2508 40.9418 29.4788 40.0926 29.9158C39.2434 30.3528 38.5108 30.9862 37.9556 31.7634L37.8431 31.9199"
          stroke="#005D69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <rect
        x="65.5"
        y="62"
        width="59"
        height="55"
        rx="7.5"
        transform="rotate(-180 65.5 62)"
        stroke="#005D69"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_434_58697"
        x="0"
        y="0.5"
        width="72"
        height="68"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.906458 0 0 0 0 0.915167 0 0 0 0 0.95 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_434_58697" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_434_58697"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_434_58697">
        <rect width="24" height="24" fill="white" transform="translate(48 46.5) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);
const BackWardsIcon = props => <Icon component={backwardsIcon} {...props} />;
export default BackWardsIcon;
