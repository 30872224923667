export default {
  'overview.title.upload': 'Tải tệp tin của bạn lên',
  'overview.text.pull': 'Kéo thả video vào đây',
  'overview.text.or.upload': 'hoặc tải tệp lên',
  'overview.text.desc': 'Loại tệp tin phải là .mp4, .mov',
  'upload.text.desc': 'Loại tệp tin phải là .png, .jpg',
  'overview.text.required': 'File không đúng định dạng!',
  'overview.text.required.file': 'Vui lòng chọn tệp tin đúng định dạng .mp4 hoặc .mov',
  'overview.text.size-big': 'Tệp tin lớn hơn 1GB',
};
