import React from 'react';

import Icon from '@ant-design/icons';

const changePasswordSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      d="M19.8346 11.6667V9.33333C19.8346 6.11167 17.223 3.5 14.0013 3.5C10.7796 3.5 8.16797 6.11167 8.16797 9.33333V11.6667M14.0013 16.9167V19.25M10.268 24.5H17.7346C19.6948 24.5 20.6749 24.5 21.4236 24.1185C22.0822 23.783 22.6176 23.2475 22.9532 22.589C23.3346 21.8403 23.3346 20.8602 23.3346 18.9V17.2667C23.3346 15.3065 23.3346 14.3264 22.9532 13.5777C22.6176 12.9191 22.0822 12.3837 21.4236 12.0481C20.6749 11.6667 19.6948 11.6667 17.7346 11.6667H10.268C8.30779 11.6667 7.32769 11.6667 6.579 12.0481C5.92043 12.3837 5.385 12.9191 5.04945 13.5777C4.66797 14.3264 4.66797 15.3065 4.66797 17.2667V18.9C4.66797 20.8602 4.66797 21.8403 5.04945 22.589C5.385 23.2475 5.92043 23.783 6.579 24.1185C7.32769 24.5 8.30779 24.5 10.268 24.5Z"
      stroke="#005D69"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ChangePassword = props => <Icon component={changePasswordSvg} {...props} />;
export default ChangePassword;
