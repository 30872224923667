export default {
  'alumnies.table.title': 'Danh sách cựu sinh viên',
  'alumni.name': 'Cựu sinh viên',
  'alumni.tab.image': 'Hình ảnh hoạt động',
  'alumni.tab.alumni': 'Cựu sinh viên',
  'add.alumnies': 'Thêm cựu sinh viên',
  'alumnies.picture': 'Hình ảnh',
  'alumnies.title': 'Họ và tên',
  'alumnies.content': 'Mô tả',
  'alumnies.table.display': 'Hiển thị',
  'alumnies.action': 'Hành động',
  'alumnies.delete.title': 'Xác nhận xóa cựu sinh viên',
  'alumnies.delete.content': 'Cựu sinh viên này sẽ xóa khỏi hệ thống',
  'alumnies.modal.titleLayout': 'Bố cục',
  'alumnies.pageSize': 'Số thư mục',
  'alumnies.modal.page': 'trang',
  'alumnies.modal.view.layout': 'Xem trước bố cục',
  'alumnies.create': 'Thêm cựu sinh viên',
  'alumnies.update': 'Cập nhật cựu sinh viên',
  'alumnies.modal.picture': 'Hình ảnh',
  'alumnies.modal.title': 'Họ và tên',
  'alumnies.modal.content': 'Mô tả',
  'alumni.activity.picture': 'Hình ảnh hoạt động',
};
