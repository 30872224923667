import React from 'react';

import Icon from '@ant-design/icons';

const playIcon = () => (
  <svg className="play-icon" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0417 9.71096C17.6632 10.623 18.4739 11.0791 18.744 11.6783C18.9794 12.2008 18.9794 12.7992 18.744 13.3217C18.4739 13.9209 17.6632 14.377 16.0417 15.289L9.76883 18.8175C8.2012 19.6993 7.41738 20.1402 6.77534 20.0676C6.21522 20.0043 5.70782 19.7076 5.37802 19.2504C5 18.7264 5 17.8271 5 16.0285V8.97151C5 7.17288 5 6.27357 5.37802 5.74957C5.70782 5.29242 6.21522 4.99567 6.77534 4.93235C7.41738 4.85977 8.2012 5.30067 9.76884 6.18247L16.0417 9.71096Z"
      stroke="#F26D21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const PlayIcon = props => <Icon component={playIcon} {...props} />;
export default PlayIcon;
