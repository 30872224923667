export default {
  'setting.name': 'Setting',
  'roles.title': 'List role',
  'roles.name': 'Name of role',
  'role.management': 'User management',
  'role.management.breadcrumb': 'Manage role',
  'roles.rolePermissions': 'Permissions',
  'roles.permissionCodes': 'Permissions',
  'roles.createdAt': 'Created at',
  'roles.action': 'Action',
  'Per.Users.Create': 'Add user',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'View user',
  'roles.create': 'Create role',
  'roles.information': 'Role information',
  'roles.update': 'Edit role',
  'Mes.Users.Role.UpdateSuccessfully': 'Update role successfully',
  'Mes.Roles.Add.Successfully': 'Create role successfully',
  'roles.code': 'Role code',
  'roles.numberOfAssign': 'The number of accounts is assigned',

  'roles.description': 'Describe',
  'roles.tab.name': 'Role list',
  'role.name': 'Name of role',
  'role.code': 'Role code',
  'role.description': 'Describe',
  'roles.roles': 'Functional group',
  'roles.groupRole': 'Functional',
  'role.placeholder.roleName': 'Please enter the role name',
  'role.placeholder.roleCode': 'Please enter the fun code',
  'role.placeholder.description': 'Please enter the description',
  'roles.delete.title': 'Confirm the role of role?',
  'roles.delete.content': 'Information data this role will be deleted.',
  'roles.add.title': 'Add role',
  'roles.edit': 'Update the role',
  'roles.info': 'Role information',
  'role.titleRight': 'Functional decentralization',
  'role.validatorName': 'Name of the role of incorrect format',
  'role.validatorUndefined': 'Please enter the role name',
  'role.validatorUndefinedCode': 'Please enter the role code',
  'role.validatorCode': 'The role code is not in the format',
  'role.warning': 'Please choose functional decentralization',

  Users: 'User',
  Roles: 'Role',
  Areas: 'Area',
  Gifts: 'Gifts',
  Locations: 'Location',
  RecyclingMachines: 'Recycling machine',
  Robots: 'Robot',
  UsersView: 'View user',
  UsersViewDetail: 'See user details',
  UsersCreate: 'Create user',
  UsersUpdate: 'Update user',
  UsersDelete: 'Delete user',
  AreasView: 'See the area',
  AreasViewDetail: 'See area details',
  AreasCreate: 'Create area',
  AreasUpdate: 'Update area',
  AreasDelete: 'Delete the area',
  LocationsView: 'See location',
  LocationsViewDetail: 'See details',
  LocationsCreate: 'Create',
  LocationsUpdate: 'Location update',
  LocationsDelete: 'Delete position',
  GiftsView: 'View gift list',
  GiftsViewDetail: 'See gift details',
  GiftsCreate: 'Create gifts',
  GiftsUpdate: 'Gift update',
  GiftsDelete: 'Delete gifts',
  RecyclingMachinesView: 'See the list of recycling machines',
  RecyclingMachinesViewDetail: 'See detailed recycling machine',
  RecyclingMachinesCreate: 'Create recycling machines',
  RecyclingMachinesUpdate: 'Update recycling machine',
  RecyclingMachinesDelete: 'Delete recycling machines',
  RobotsView: 'See the Robot List',
  RobotsViewDetail: 'See Robot details',
  RobotsCreate: 'Create robots',
  RobotsUpdate: 'Robot update',
  RobotsDelete: 'Delete robots',
  RolesView: 'See the role',
  RolesViewDetail: 'See details',
  RolesCreate: 'Tao go trò',
  RolesUpdate: 'Update the role',
  RolesDelete: 'Delete the role',
};
