import React from 'react';
import { Award } from 'react-feather';

import { getListAchievements } from '@modules/achievements/achievementsRepository';
import { IRouter } from '@routers/interface';

export const routerAchievement: IRouter = {
  path: '/achievement',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'achievement.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Award />,
  },
  generatePath() {
    return '/achievement?tab=1';
  },
  searchApi: [
    {
      key: '2',
      apiServices: getListAchievements,
    },
  ],
};
