import React from 'react';

import { IRouter } from '@routers/interface';

export const routeFormMapAdd: IRouter = {
  path: '/facility/tab=2/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'map.add.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routeFormMapEdit: IRouter = {
  path: '/facility/tab=2/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'map.edit.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};