import React from 'react';

import CollaborationIcon from '@assets/icon/collaborationIcon';
import { IRouter } from '@routers/interface';

export const routerCollaboration: IRouter = {
  path: '/collaboration',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'collaboration.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <CollaborationIcon />,
  },
  generatePath() {
    return '/collaboration?tab=1';
  },
};
