import React from 'react';

import Icon from '@ant-design/icons';

const eyeIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7091 11.5333C18.46 11.3576 19.2288 11.2697 20 11.2715C27.6364 11.2715 32 19.9988 32 19.9988C31.3378 21.2376 30.5481 22.404 29.6436 23.4788M22.3127 22.3115C22.0131 22.6331 21.6518 22.891 21.2503 23.0698C20.8489 23.2487 20.4155 23.3449 19.9761 23.3526C19.5367 23.3604 19.1002 23.2796 18.6927 23.115C18.2852 22.9504 17.915 22.7054 17.6042 22.3946C17.2934 22.0838 17.0484 21.7136 16.8838 21.3061C16.7192 20.8986 16.6384 20.4621 16.6461 20.0227C16.6539 19.5833 16.7501 19.1499 16.929 18.7484C17.1078 18.347 17.3657 17.9857 17.6873 17.6861M26.48 26.4788C24.6152 27.9002 22.3445 28.6877 20 28.7261C12.3636 28.7261 8 19.9988 8 19.9988C9.35697 17.4699 11.2391 15.2605 13.52 13.5188L26.48 26.4788Z"
      stroke="#495253"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 8L32 32"
      stroke="#495253"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const EyeIcon = props => <Icon component={eyeIcon} {...props} />;
export default EyeIcon;
