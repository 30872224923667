export default {
  'organization.name': 'Cơ cấu tổ chức',
  'orgStructure.tab.title': 'Bộ máy tổ chức',
  'socialOrg.tab.title': 'Đảng - Đoàn thể',
  'listOrgStructurePeriods.title': 'Danh sách giai đoạn',
  'listOrgStructureDivisions.title': 'Danh sách nội dung',
  'listOrgStructurePeriods.delete.title': 'Xác nhận xóa giai đoạn',
  'listOrgStructurePeriods.delete.content': 'Giai đoạn này sẽ xóa khỏi hệ thống',
  'listOrgStructureDivisions.add': 'Thêm thư mục chính',
  'listOrgStructurePeriods.add': 'Thêm giai đoạn',
  'listOrgStructurePeriods.from-to': 'Giai đoạn',
  'orgStructureTopics.delete.title': 'Xác nhận xóa thư mục chính',
  'orgStructureTopics.delete.content': 'Thư mục chính này sẽ xóa khỏi hệ thống',
  'orgStructureDivision.delete.title': 'Xác nhận xóa nội dung',
  'orgStructureDivision.delete.content': 'Nội dung này sẽ xóa khỏi hệ thống',
  'modalDivisions.title': 'Tiêu đề',
  'modalDivisions.update': 'Cập nhật thư mục chính',
  'modalDivisions.create': 'Thêm thư mục chính',
  'modalPeriods.title': 'Tiêu đề',
  'modalPeriods.type': 'Phân loại',
  'modalPeriods.description': 'Mô tả',
  'modalPeriods.information': 'Chi tiết giai đoạn',
  'modalPeriods.update': 'Cập nhật giai đoạn',
  'modalPeriods.create': 'Thêm giai đoạn',
  'modalPeriods.layout': 'Cập nhật bố cục',
  'modalPeriods.view': 'Xem trước bố cục',
  'modalPeriods.pageSize': 'Số thư mục',
  'modalPeriods.pl.pageSize': 'Vui lòng chọn số thư mục',
  'modalPeriods.display': 'Trạng thái hiển thị',
  'modalTopics.title': 'Tiêu đề',
  'modalTopics.description': 'Mô tả',
  'modalTopics.information': 'Chi tiết nội dung',
  'modalTopics.update': 'Cập nhật nội dung',
  'modalTopics.create': 'Thêm nội dung',
  'modalPeriods.fromTo': 'Giai đoạn',
  'modalPeriods.pl.fromTo': 'Vui lòng nhập giai đoạn',

  'organization.update': 'Cập nhật tổ chức',
  'organization.create': 'Thêm tổ chức',
  'periods.update': 'Cập nhật giai đoạn',
  'periods.create': 'Thêm giai đoạn',
  'term.update': 'Cập nhật nhiệm kỳ',
  'term.create': 'Thêm nhiệm kỳ',
  'members.update': 'Cập nhật thành viên',
  'members.create': 'Thêm thành viên',
  'modalDivisions.name': 'Tiêu đề',
  'member.delete.title': 'Xác nhận xóa thành viên',
  'member.delete.content': 'Thành viên này sẽ xóa khỏi hệ thống',
  'organization.delete.title': 'Xác nhận xóa tổ chức',
  'organization.delete.content': 'Tổ chức này sẽ xóa khỏi hệ thống',
  'periods.delete.title': 'Xác nhận xóa giai đoạn',
  'periods.delete.content': 'Giai đoạn này sẽ xóa khỏi hệ thống',
  'term.delete.title': 'Xác nhận xóa nhiệm kỳ',
  'term.delete.content': 'Nhiệm kỳ này sẽ xóa khỏi hệ thống',
  'listSocialOrgPeriods.delete.title': 'Xác nhận xóa giai đoạn',
  'listSocialOrgPeriods.delete.content': 'Giai đoạn này sẽ xóa khỏi hệ thống',
  'listSocialOrgPeriods.content-title': 'Danh sách nội dung',
  'organizational.add':'Thêm tổ chức',
  'modalDivisions.description': 'Mô tả',
};
