export default {
  'map.table.title': 'Danh sách địa điểm',
  'facility.name': 'Cơ sở vật chất',
  'facility.tab.map': 'Bản đồ',
  'map.province': 'Tỉnh thành',
  'map.address': 'Địa điểm',
  'map.action': 'Hành động',
  'map.layout': 'Bố cục',
  'add.map': 'Thêm địa điểm',
  'map.delete.title': 'Xác nhận xóa địa điểm',
  'map.delete.content': 'Địa điểm này sẽ xóa khỏi hệ thống',
  'map.modal.province': 'Tỉnh thành',
  'map.modal.schoolName': 'Trường',
  'map.modal.branchName': 'Cơ sở',
  'map.modal.address': 'Địa chỉ',
  'map.modal.abbreviatedSchoolName': 'Ký hiệu',
  'map.modal.acreage': 'Diện tích',
  'map.modal.description': 'Mô tả',
  'map.modal.avatar': 'Hình đại diện',
  'map.pl.abbreviatedSchoolName': 'Vui lòng nhập kí hiệu',
  'map.pl.acreage': 'Vui lòng nhập diện tích',
  'map.pl.description': 'Vui lòng nhập mô tả',
  'map.add.name': 'Địa điểm',
  'mapForm.add.name': 'Thêm địa điểm mới',
  'mapForm.update.name': 'Cập nhật địa điểm mới',
  'mapForm.info.name': 'Xem thông tin địa điểm',
  'map.name': 'Bản đồ',
  'map.modal.location': 'Vị trí',
  'add.location': 'Chọn vị trí',
  'map.modal.image-real': 'Hình ảnh thực tế',
  'modal.actualImage': 'Hình ảnh thực tế',
  'map.branch': 'Cơ sở',
};
