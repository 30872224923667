import React from 'react';

import Icon from '@ant-design/icons';

const teamIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.91667 19.8333C10.5275 19.8333 11.8333 18.5275 11.8333 16.9167C11.8333 15.3058 10.5275 14 8.91667 14C7.30584 14 6 15.3058 6 16.9167C6 18.5275 7.30584 19.8333 8.91667 19.8333Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5846 19.8333C22.1955 19.8333 23.5013 18.5275 23.5013 16.9167C23.5013 15.3058 22.1955 14 20.5846 14C18.9738 14 17.668 15.3058 17.668 16.9167C17.668 18.5275 18.9738 19.8333 20.5846 19.8333Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.7507 8.16659C16.3615 8.16659 17.6673 6.86075 17.6673 5.24992C17.6673 3.63909 16.3615 2.33325 14.7507 2.33325C13.1398 2.33325 11.834 3.63909 11.834 5.24992C11.834 6.86075 13.1398 8.16659 14.7507 8.16659Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.08398 25.6665C3.08398 22.4448 5.69557 19.8332 8.91732 19.8332C12.1391 19.8332 14.7507 22.4448 14.7507 25.6665C14.7507 22.4448 17.3622 19.8332 20.584 19.8332C23.8057 19.8332 26.4173 22.4448 26.4173 25.6665M20.584 13.9998C20.584 10.7781 17.9724 8.1665 14.7507 8.1665C11.5289 8.1665 8.91732 10.7781 8.91732 13.9998"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const TeamIcon = props => <Icon component={teamIcon} {...props} />;
export default TeamIcon;
