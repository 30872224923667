import React from 'react';

import Icon from '@ant-design/icons';

const userIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.6673 28C26.6673 26.1392 26.6673 25.2089 26.4377 24.4518C25.9206 22.7473 24.5867 21.4134 22.8822 20.8963C22.1251 20.6667 21.1947 20.6667 19.334 20.6667H12.6673C10.8066 20.6667 9.87619 20.6667 9.11913 20.8963C7.41459 21.4134 6.0807 22.7473 5.56364 24.4518C5.33398 25.2089 5.33398 26.1392 5.33398 28M22.0007 10C22.0007 13.3137 19.3144 16 16.0007 16C12.6869 16 10.0007 13.3137 10.0007 10C10.0007 6.68629 12.6869 4 16.0007 4C19.3144 4 22.0007 6.68629 22.0007 10Z"
      stroke="#005D69"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UserIcon = props => <Icon component={userIcon} {...props} />;
export default UserIcon;
