export default {
  'reset.password.title': 'Đặt lại mật khẩu',
  'reset.password.newPassword': 'Mật khẩu mới',
  'reset.password.confirm.newPassword': 'Xác nhận mật khẩu mới',
  'reset.password.title.error': 'Lỗi kết nối !!',
  'reset.password.notification':
    'Đã có lỗi trong quá trình kết nối hoặc link của bạn đã hết hạn. Vui lòng thử lại hoặc yêu cầu gửi lại link để đặt lại mật khẩu của bạn.',
  'reset.password.not.match': 'Hai mật khẩu bạn đã nhập không khớp!',
  'reset.password.required': 'Vui lòng nhập mật khẩu mới',
  'reset.confirm.password.required': 'Vui lòng nhập xác nhận mật khẩu mới',
  'reset.password.invalid': 'Mật khẩu mới không đúng định dạng',
};
