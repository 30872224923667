export default {
  'leaderships.name': 'Lãnh đạo',
  'leaderships.title': 'Danh sách lãnh đạo',
  'timeline.title': 'Danh sách giai đoạn',

  'leadershipPeriods.add': 'Thêm',
  'leadershipPeriods.from-to': 'Giai đoạn',
  'leadershipPeriods.pageSize': 'Bố cục',
  'leadershipPeriods.delete.title': 'Xác nhận xóa giai đoạn',
  'leadershipPeriods.delete.content': 'Giai đoạn này sẽ xóa khỏi hệ thống',
  'modalLeadershipPeriod.fromTo': 'Giai đoạn',
  'modalLeadershipPeriod.pl.fromTo': 'Vui lòng nhập giai đoạn',
  'modalLeadershipPeriod.description': 'Mô tả',
  'modalLeadershipPeriod.type': 'Phân loại',
  'modalLeadershipPeriod.pageSize': 'Số thư mục',
  'modalLeadershipPeriod.display': 'Trạng thái hiển thị',
  'modalLeadershipPeriod.create': 'Thêm giai đoạn',
  'modalLeadershipPeriod.update': 'Cập nhật giai đoạn',
  'modalLeadershipPeriod.information': 'Chi tiết giai đoạn',

  'modalLeadershipBranchs.create': 'Thêm phân hiệu',
  'modalLeadershipBranchs.update': 'Cập nhật phân hiệu',
  'modalLeadershipBranchs.information': 'Chi tiết phân hiệu',
  'modalLeadershipBranchs.name': 'Tên phân hiệu',
  'leadershipBranchs.delete.title': 'Xác nhận xóa phân hiệu',
  'leadershipBranchs.delete.content': 'Phân hiệu này sẽ xóa khỏi hệ thống',

  'modalLeader.create': 'Thêm lãnh đạo',
  'modalLeader.update': 'Cập nhật lãnh đạo',
  'modalLeader.information': 'Chi tiết lãnh đạo',
  'leader.name': 'Tên lãnh đạo',
  'leader.term': 'Nhiệm kỳ',
  'leader.position': 'Chức vụ (năm đảm nhiệm)',
  'leader.userAvatar': 'Ảnh đại diện',
  'leader.description': 'Mô tả',
  'leader.delete.title': 'Xác nhận xóa lãnh đạo',
  'leader.delete.content': 'Lãnh đạo này sẽ xóa khỏi hệ thống',
  'modalLeadershipPeriod.toVN.valid': 'Giai đoạn không hợp lệ',
  'leadershipPeriods.copy.title': 'Xác nhận sao chép giai đoạn',
  'leadershipPeriods.copy.content': 'Giai đoạn {year} sẽ được sao chép',
  'leadershipPeriods.division.title': 'Thêm',
  'leadershipPeriods.division.save': 'Lưu Thay Đổi',
};
