export default {
  'achievement.tab.ranking': 'Bảng xếp hạng',
  'achievement.tab.UEH': 'Thành tích UEH',
  'achievement.tab.achIndAColls': 'Thành tích cá nhân và tập thể',
  'achievement.tab.achHonorees': 'Vinh danh cán bộ công nhân viên',
  //Chart
  'charts.table.title': 'Danh sách bảng xếp hạng',
  'achievement.name': 'Thành tựu',
  'charts.picture': 'Hình ảnh',
  'charts.title': 'Tiêu đề',
  'charts.content': 'Nội dung',
  'charts.display': 'Trạng thái hiển thị',
  'charts.action': 'Hành động',
  'charts.delete.title': 'Xác nhận xóa xếp hạng',
  'charts.delete.content': 'Xếp hạng này sẽ xóa khỏi hệ thống',
  'charts.pageSize': 'Số thư mục',
  'charts.modal.picture': 'Hình ảnh',
  'charts.modal.title': 'Tiêu đề',
  'charts.modal.content': 'Nội dung',
  'charts.create': 'Thêm xếp hạng mới',
  'charts.update': 'Cập nhật xếp hạng mới',
  'add.layout': 'Bố cục',
  'add.charts': 'Thêm xếp hạng',
  'charts.table.display': 'Hiển thị',
  'charts.modal.layout': 'Vui lòng chọn bố cục',
  'charts.modal.view.layout': 'Xem trước bố cục',
  'charts.modal.page': 'trang',

  //Thành tích UEH
  'achievementUeh.table.title': 'Danh sách thành tích UEH',
  'add.achievementUeh': 'Thêm thành tích',
  'achievementUeh.delete.title': 'Xác nhận xóa thành tích',
  'achievementUeh.delete.content': 'Thành tích này sẽ xóa khỏi hệ thống',
  'achievementUeh.modal.picture': 'Hình ảnh',
  'achievementUeh.modal.title': 'Tiêu đề',
  'achievementUeh.modal.content': 'Nội dung',
  'achievementUeh.action': 'Hành động',
  'achievementUeh.create': 'Thêm thành tích mới',
  'achievementUeh.update': 'Cập nhập thành tích mới',
  'achievementUeh.pageSize': 'Số thư mục',
  'achievementUeh.display': 'Trạng thái hiển thị',
  //achIndAColls
  'achIndAColls.title': 'Danh sách loại thành tích',
  'modalAchIndAColls.create': 'Thêm loại thành tích mới',
  'add.achIndAColls': 'Thêm loại thành tích',
  'modalAchIndAColls.title': 'Tiêu đề',
  'modalAchIndAColls.content': 'Nội dung',
  'modalAchIndAColls.pageSize': 'Số thư mục',
  'modalAchIndAColls.display': 'Trạng thái hiển thị',
  'typeAchInAColls.delete.title': 'Xác nhận xóa loại thành tích',
  'typeAchInAColls.delete.content': 'Loại thành tích này sẽ xóa khỏi hệ thống',
  'member.delete.title': 'Xác nhận xóa thành viên',
  'member.delete.content': 'Thành viên này sẽ xóa khỏi hệ thống',
  'achInAColls.title': 'Danh sách loại thành tích',
  'add.typeColl': 'Thêm loại thành tích',
  'modalAchIndAColls.update': 'Cập nhật loại thành tích mới',
  'modalAchIndAColls.member.create': 'Thêm thành viên mới',
  'modalAchIndAColls.member.update': 'Cập nhật thành viên mới',

  // Vinh danh cán bộ công nhân viên
  'achHonorees.status': '{status,select, 1 {Bình thường} 2 {Đã mất} other {Không xác định}}',
  'honoringEmployeesWorkers.year': 'Năm',
  'honoringEmployeesWorkers.type': 'Phân loại',
  'honoringEmployeesWorkers.title': 'Danh sách nội dung thành tích',
  'honoringEmployeesWorkers.year-title': 'Danh sách năm',
  'honoring-employees-workers.delete.title': 'Xác nhận xóa năm',
  'honoring-employees-workers.delete.content': 'Số năm này sẽ xóa khỏi hệ thống',
  'achTitle.delete.title': 'Xác nhận xóa danh hiệu',
  'achTitle.delete.content': 'Danh hiệu này sẽ xóa khỏi hệ thống',
  'achHonorees.delete.title': 'Xác nhận xóa thành viên',
  'achHonorees.delete.content': 'Thành viên này sẽ xóa khỏi hệ thống',
  'honoringEmployeesWorkers.update': 'Cập nhật năm',
  'honoringEmployeesWorkers.create': 'Thêm năm',
  'honoringEmployeesWorkers.information': 'Chi tiết năm',
  'honoringEmployeesWorkers.pageSize': 'Số thư mục',
  'honoringEmployeesWorkers.display': 'Trạng thái hiển thị',
  'modalAchTitles.create': 'Thêm danh hiệu',
  'modalAchTitles.update': 'Cập nhật danh hiệu',
  'modalAchTitles.name': 'Tên danh hiệu',
  'modalAchHonorees.update': 'Cập nhật thành viên',
  'modalAchHonorees.create': 'Thêm thành viên',
  'modalAchHonorees.name': 'Tên cá nhân, tổ chức',
  'modalAchHonorees.unit': 'Đơn vị',
  'modalAchHonorees.position': 'Vị trí',
  'modalAchHonorees.vitalStatus': 'Trạng thái',
  'honoringEmployeesWorkers.year-add': 'Thêm năm',
  'achTitles.add': 'Thêm danh hiệu',
};
