import React from 'react';

import Icon from '@ant-design/icons';

const mailIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.03838 10.668C4.03838 10.668 11.116 15.1504 14.6667 16.0013C15.2794 16.1481 16.7206 16.1481 17.3333 16.0013C21.0196 15.1179 27.9616 10.668 27.9616 10.668M10.4 25.3346H21.6C23.8402 25.3346 24.9603 25.3346 25.816 24.8987C26.5686 24.5152 27.1805 23.9032 27.564 23.1506C28 22.295 28 21.1748 28 18.9346V13.068C28 10.8278 28 9.70765 27.564 8.85201C27.1805 8.09936 26.5686 7.48744 25.816 7.10394C24.9603 6.66797 23.8402 6.66797 21.6 6.66797H10.4C8.15979 6.66797 7.03969 6.66797 6.18404 7.10394C5.43139 7.48744 4.81947 8.09936 4.43597 8.85201C4 9.70765 4 10.8278 4 13.068V18.9346C4 21.1748 4 22.295 4.43597 23.1506C4.81947 23.9032 5.43139 24.5152 6.18404 24.8987C7.03969 25.3346 8.15979 25.3346 10.4 25.3346Z"
      stroke="#005D69"
      stroke-width="2.6672"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const MailIcon = props => <Icon component={mailIcon} {...props} />;
export default MailIcon;
