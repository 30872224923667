import 'dayjs/locale/vi';

import viVN from 'antd/lib/locale/vi_VN';

import achievement from './achievement';
import alumni from './alumni';
import auth from './auth';
import collaboration from './collaboration';
import common from './common';
import facility from './facility';
import Form from './form';
import leaderships from './leaderships';
import organization from './organization';
import overviewVideo from './overviewVideo';
import pageError from './pageError';
import roles from './roles';
import scientificResearch from './scientificResearch';
import server from './server';
import team from './team';
import user from './user';

export default {
  ...viVN,
  ...common,
  ...overviewVideo,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...user,
  ...organization,
  ...leaderships,
  ...achievement,
  ...alumni,
  ...collaboration,
  ...collaboration,
  ...alumni,
  ...team,
  ...facility,
  ...scientificResearch,
  Form,
};
