import React from 'react';

import LeaderIcon from '@assets/icon/LeaderIcon';
import { IRouter } from '@routers/interface';

export const routerLeaderships: IRouter = {
  path: '/leaderships',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'leaderships.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <LeaderIcon />,
  },
  generatePath() {
    return '/leaderships?tab=1';
  },
};
