import React from 'react';

import Icon from '@ant-design/icons';

const Pause = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8615 8.84006H3.63846C3.01231 8.84006 2.5 9.38896 2.5 10.0598C2.5 10.7307 3.01231 11.2796 3.63846 11.2796H19.8615C20.4877 11.2796 21 10.7307 21 10.0598C21 9.38896 20.4877 8.84006 19.8615 8.84006ZM19.8615 3.96094H3.63846C3.01231 3.96094 2.5 4.50984 2.5 5.18072C2.5 5.8516 3.01231 6.4005 3.63846 6.4005H19.8615C20.4877 6.4005 21 5.8516 21 5.18072C21 4.50984 20.4877 3.96094 19.8615 3.96094ZM3.63846 16.1587H10.4692C11.0954 16.1587 11.6077 15.6098 11.6077 14.939C11.6077 14.2681 11.0954 13.7192 10.4692 13.7192H3.63846C3.01231 13.7192 2.5 14.2681 2.5 14.939C2.5 15.6098 3.01231 16.1587 3.63846 16.1587Z"
        fill="#F26D21"
      />
      <path
        d="M19.5771 21.0395V14.3984"
        stroke="#F26D21"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8335 21.0395V14.3984"
        stroke="#F26D21"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PauseIcon = props => <Icon component={Pause} {...props} />;
export default PauseIcon;
