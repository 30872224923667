import React from 'react';

import Icon from '@ant-design/icons';

const videoUploadSvg = () => (
  <svg width="100" height="99" viewBox="0 0 100 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 66.9992C12.0253 63.6692 8.75 57.9984 8.75 51.5625C8.75 41.8953 16.14 33.9541 25.5789 33.0799C27.5097 21.335 37.7085 12.375 50 12.375C62.2915 12.375 72.4903 21.335 74.4211 33.0799C83.86 33.9541 91.25 41.8953 91.25 51.5625C91.25 57.9984 87.9747 63.6692 83 66.9992M33.5 66L50 49.5M50 49.5L66.5 66M50 49.5V86.625"
      stroke="#005D69"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const VideoUploadIcon = props => <Icon component={videoUploadSvg} {...props} />;
export default VideoUploadIcon;
