import React from 'react';

import { IRouter } from '@routers/interface';

export const routerMap: IRouter = {
  path: '/map',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'map.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
