import { routerAchIndAColls } from '@view/Achievement/Pages/AchIndAColls/router';
import { routerAchievement } from '@view/Achievement/router';
import { routerAlumnies } from '@view/Alumni/Page/Alumnies/router';
import { routerAlumni } from '@view/Alumni/router';
import { routerForgotPassword } from '@view/Auth/ForgotPassword/router';
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import {
  routerAddCountry,
  routerEditCountry
} from '@view/Collaboration/Page/InterEduIns/component/AddCountry/router';
import { routerCollaboration } from '@view/Collaboration/router';
import { routeFormMapAdd, routeFormMapEdit } from '@view/Facility/Map/FormMap/router';
import { routerMap } from '@view/Facility/Map/router';
import { routerFacility } from '@view/Facility/router';
import { routerHome } from '@view/Home/router';
import { routerLeaderships } from '@view/Leaderships/router';
import { routerOrganization } from '@view/Organization/router';
import { routerPageError } from '@view/PageError/router';
import { routerScientificResearch } from '@view/ScientificResearch/router';
import {
  routerSetting,
  routerSettingOverviewVideo,
  routerSettingScreenSaverVideoSetting,
  routerUser
} from '@view/Setting/router';
import { routerTeam } from '@view/Team/router';
import { routerTraining } from '@view/Training/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerHome,
  routerLeaderships,
  routerAchievement,
  routerOrganization,
  routerTeam,
  routerViewProfile,
  routerAchIndAColls,
  routerTraining,
  routerFacility,
  routerCollaboration,
  routerScientificResearch,
  routerAlumni,
  routeFormMapAdd,
  routeFormMapEdit,
  routerMap,
  routerSetting,
  routerUser,
  routerAlumnies,
  routerAddCountry,
  routerEditCountry,
  routerSettingOverviewVideo,
  routerSettingScreenSaverVideoSetting,
  routerPageError,
];
export const publicPage: IRouter[] = [
  routerForgotPassword,
  routerResetPassword,
  routerLogin,
  routerPageError,
];
