import React from 'react';

import { FacilityIcon } from '@assets/icon/facilityIcon';
import { IRouter } from '@routers/interface';

export const routerFacility: IRouter = {
  path: '/facility',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'facility.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <FacilityIcon />,
  },
  generatePath() {
    return '/facility?tab=1';
  },
};
