import React from 'react';

import { IRouter } from '@routers/interface';

export const routerAddCountry: IRouter = {
  path: '/collaboration/tab=6/add/:regionIdParam',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'interEduIns.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerEditCountry: IRouter = {
  path: '/collaboration/tab=6/edit/:id/:regionIdParam',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'interEduIns.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
