import { PaginationEntity } from '@core/pagination/entity';
import httpRepository from '@core/repository/http';
import { OptionEntity, TableEntity } from '@core/table';

import AchievementsEntity, {
  AchIndACollsEntity,
  AchIndACollsTypeEntity
} from './achievementsEntity';

// API GET
export const getListAchievements = (pagination?: PaginationEntity, options?: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/Achievements',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: AchievementsEntity.createListAchievements(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};
//and get detail
export const getDetailAchievements = (id: string) => {
  return httpRepository.execute({
    path: '/api/Achievements/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new AchievementsEntity(res);
    },
  });
};

//API ADD
export const createAchievements = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Achievements',
    method: 'post',
    payload,
    config: { isFormData: true },
  });
};

//API EDIT/UPDATE
export const updateAchievements = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/Achievements/' + id,
    method: 'put',
    payload,
    config: { isFormData: true },
  });
};

//API DELETE
export const deleteAchievements = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Achievements/DeleteMany',
    method: 'post',
    payload,
  });
};

export const updateAchievementsSortOrders = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Achievements/SortOrders',
    method: 'put',
    payload,
  });
};
// Layout

export const addLayoutAchievements = (type, payload: any) => {
  return httpRepository.execute({
    path: '/api/AchievementCompositions/' + type,
    method: 'put',
    payload,
  });
};

//Delete multiple achievement of UEH individuals and collectives.
export const deleteAchIndAColls = (payload: any) => {
  return httpRepository.execute({
    path: '/api/AchIndAColls/DeleteMany',
    method: 'post',
    payload,
  });
};

export const getListAchievementCompositions = (
  pagination: PaginationEntity,
  options: OptionEntity,
  type: number,
) => {
  const paramsTable = new TableEntity(pagination, options);
  const params = { ...paramsTable, type };
  return httpRepository.execute({
    path: '/api/AchievementCompositions/' + type,
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return new AchievementsEntity(res);
    },
  });
};

export const getListAchIndAColls = (pagination?: PaginationEntity, options?: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/AchIndAColls',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: AchievementsEntity.createListAchievements(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};

export const getListAchIndACollType = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/AchIndACollTypes',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: AchIndACollsTypeEntity.createListAchievements(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};

export const createAchIndACollType = (payload: any) => {
  return httpRepository.execute({
    path: '/api/AchIndACollTypes',
    method: 'post',
    payload,
  });
};

export const updateAchIndACollType = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/AchIndACollTypes/' + id,
    method: 'put',
    payload,
  });
};

export const getDetailAchIndACollType = (id: string) => {
  return httpRepository.execute({
    path: '/api/AchIndACollTypes/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new AchIndACollsTypeEntity(res);
    },
  });
};

export const deleteAchIndACollsType = (payload: any) => {
  return httpRepository.execute({
    path: '/api/AchIndACollTypes/DeleteMany',
    method: 'post',
    payload,
  });
};

export const createAchIndAColl = (payload: any) => {
  return httpRepository.execute({
    path: '/api/AchIndAColls',
    method: 'post',
    payload,
  });
};

export const updateAchIndAColl = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/AchIndAColls/' + id,
    method: 'put',
    payload,
  });
};

export const getDetailAchIndAColl = (id: string) => {
  return httpRepository.execute({
    path: '/api/AchIndAColls/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new AchIndACollsEntity(res);
    },
  });
};
