import React from 'react';

import AlumniIcon from '@assets/icon/alumniIcon';
import { IRouter } from '@routers/interface';

export const routerAlumni: IRouter = {
  path: '/alumni',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'alumni.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <AlumniIcon />,
  },
  generatePath() {
    return '/alumni?tab=1';
  },
};
