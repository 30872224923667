export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  'server.data.success': 'Thành công',
  Successful: 'Thành công',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Link khôi phục đã được gửi tới email của bạn',
  'Mes.User.ResetPasswordSuccessfully': 'Đặt lại mật khẩu thành công',
  'Mes.Profile.GetDetailSuccessfully': 'Lấy thông tin tài khoản thành công',
  'Mes.Profile.OldPassword.Wrong': 'Mật khẩu cũ không đúng',
  'Mes.Profile.ChangePasswordSuccessfully': 'Cập nhật mật khẩu thành công',
  'Mes.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Role.CreateSuccessfully': 'Thêm vai trò thành công',
  'Mes.Role.DeleteSuccessfully': 'Xóa vai trò thành công',
  'Mes.User.Create.Successfully': 'Tạo người dùng thành công',
  'Mes.User.Create.Failed': 'Tạo người dùng thất bại',
  'Mes.User.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.User.Update.Failed': 'Cập nhật người dùng thất bại',
  'Mes.User.Search.Successfully': 'Xem danh sách người dùng thành công',
  'Mes.User.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.User.Delete.Successfully': 'Xóa người dùng thành công',
  'Mes.User.Delete.Failed': 'Xóa người dùng thất bại',
  'Mes.User.Login.Successfully': 'Đăng nhập thành công',
  'Mes.User.Send.Failed': 'Gửi cho người dùng mail thất bại',
  'Mes.User.CheckRecoveryCode.Successfully': 'Kiểm tra mã khôi phục thành công',
  'Mes.User.SendRecoveryCode.Successfully': 'Gửi mã khôi phục thành công',
  'Mes.User.ResetPassword.Successfully': 'Lấy lại mật khẩu thành công',
  'Mes.User.Refresh.Successfully': 'Làm mới thành công',
  'Mes.User.ChangePassword.Successfully': 'Đổi mật khẩu thành công',
  'Mes.User.Profile.Successfully': 'Lấy thông tin cá nhân thành công',
  'Mes.User.Invalid.Username': 'Tên đăng nhập không hợp lệ',
  'Mes.User.AlreadyExist.Username': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Username': 'Tên đăng nhập là bắt buộc',
  'Mes.User.NotWhiteSpace.Username': 'Tên đăng nhập không có khoảng trắng',
  'Mes.User.NotSpecialCharacter.Username': 'Tên đăng nhập không chứa ký tự đặc biệt (trừ dấu . @)',
  'Mes.User.Invalid.Email': 'Email không hợp lệ',
  'Mes.User.AlreadyExist.Email': 'Email đã tồn tại',
  'Mes.User.Required.Email': 'Email là bắt buộc',
  'Mes.User.NotWhiteSpace.Email': 'Email không có khoảng trắng',
  'Mes.User.OverLength.Name': 'Tên người dùng không vượt quá 250 kí tự',
  'Mes.User.Invalid.AvatarPicture': 'Ảnh đại diện không hợp lệ',
  'Mes.User.Invalid.Password': 'Mật khẩu không hợp lệ',
  'Mes.User.Required.Password': 'Mật khẩu là bắt buộc',
  'Mes.User.IsWrong.OldPassword': 'Mật khẩu cũ không đúng',
  'Mes.User.Invalid.OldPassword': 'Mật khẩu cũ không hợp lệ',
  'Mes.User.Invalid.PhoneNumber': 'Số điện thoại không hợp lệ',
  'Mes.User.Required.RoleId': 'Id vai trò là bắt buộc',
  'Mes.User.Status.Required': 'Trạng thái là bắt buộc',
  'Mes.User.Status.Invalid': 'Trạng thái không hợp lệ',
  'Mes.User.Required.RecoveryCode': 'Mã khôi phục là bắt buộc',
  'Mes.User.NotFound.RecoveryCode': 'Mã khôi phục người dùng không tồn tại',
  'Mes.User.NotFound': 'Không tìm thấy người dùng',
  'Mes.User.PleaseCheckEmail': 'Hãy kiểm tra lại email của bạn',
  'Mes.User.Blocked': 'Người dùng đã bị khóa',
  'Mes.TeamMember.Create.Successfully': 'Tạo Thành viên của đội ngũ thành công',
  'Mes.TeamMember.Create.Failed': 'Tạo Thành viên của đội ngũ thất bại',
  'Mes.TeamMember.Update.Successfully': 'Cập nhật Thành viên của đội ngũ thành công',
  'Mes.TeamMember.Update.Failed': 'Cập nhật Thành viên của đội ngũ thất bại',
  'Mes.TeamMember.Search.Successfully': 'Xem danh sách Thành viên của đội ngũ thành công',
  'Mes.TeamMember.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.TeamMember.Delete.Successfully': 'Xóa Thành viên của đội ngũ thành công',
  'Mes.TeamMember.Delete.Failed': 'Xóa Thành viên của đội ngũ thất bại',
  'Mes.TeamMember.Repeated.Language': 'Xóa Thành viên của đội ngũ thất bại',
  'Mes.TeamMember.Required.Translations': 'Bản dịch của thành viên của đội ngũ là bắt buộc',
  'Mes.TeamMember.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.TeamMemberTranslation.Required.LanguageCode': 'Mã ngôn ngữ của bản dịch là bắt buộc',
  'Mes.TeamMemberTranslation.Required.Name': 'Tên của thành viên của đội ngũ là bắt buộc',
  'Mes.TeamMemberTranslation.OverLength.Name': 'Tên của thành viên của đội ngũ quá dài',
  'Mes.TeamMemberTranslation.Invalid.VitalStatus':
    'Tình trạng sức khỏe của thành viên không hợp lệ',
  'Mes.TeamMemberTranslation.Invalid.RecognitionYear':
    'Năm mà thành viên được công nhận không hợp lệ',
  'Mes.TeamMemberTranslation.NotFound': 'Không tìm thấy bản dịch của thành viên của đội ngũ',
  'Mes.TeamMember.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.TeamType.NotFound': 'Không tìm thấy loại đội ngũ',
  'Mes.TeamType.Create.Successfully': 'Tạo thành công',
  'Mes.TeamType.Create.Failed': 'Tạo thất bại',
  'Mes.TeamType.Update.Successfully': 'Cập nhật thành công',
  'Mes.TeamType.Update.Failed': 'Cập nhật thất bại',
  'Mes.TeamType.Search.Successfully': 'Xem danh sách thành công',
  'Mes.TeamType.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.TeamType.Delete.Successfully': 'Xóa thành công',
  'Mes.TeamType.Delete.Failed': 'Xóa thất bại',
  'Mes.TeamType.Repeated.Language': 'Xóa thất bại',
  'Mes.TeamType.Required.Translations': 'Bản dịch của đội ngũ là bắt buộc',
  'Mes.TeamType.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.TeamTypeTranslation.Required.LanguageCode': 'Mã ngôn ngữ của bản dịch là bắt buộc',
  'Mes.TeamTypeTranslation.Required.Name': 'Tên của đội ngũ là bắt buộc',
  'Mes.TeamTypeTranslation.OverLength.Name': 'Tên của đội ngũ quá dài',
  'Mes.TeamTypeTranslation.NotFound': 'Không tìm thấy bản dịch của đội ngũ',
  'Mes.TeamType.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.TeamType.Required.PageSize': 'Số trang của đội là bắt buộc',
  'Mes.TeamType.Invalid.PageSize': 'Số trang của đội ngũ không hợp lệ',
  'Mes.TeamType.Required.Display': 'Tình trạng hiển thị của đội là bắt buộc',
  'Mes.TeamOverview.Required.Year': 'Năm thành lập đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.Year': 'Năm thành lập đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.AlreadyExist.Year': 'Năm thành lập đội ngũ của đội ngũ đã tồn tại',
  'Mes.TeamOverview.Required.Professor': 'Số lượng giáo sư trong đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.Professor': 'Số lượng giáo sư trong đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.Required.AssociateProfessor':
    'Số lượng phó giáo sư trong đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.AssociateProfessor':
    'Số lượng phó giáo sư trong đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.Required.Doctor': 'Số lượng tiến sĩ trong đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.Doctor': 'Số lượng tiến sĩ trong đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.Required.Master': 'Số lượng thạc sĩ trong đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.Master': 'Số lượng thạc sĩ trong đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.Required.Bachelor': 'Số lượng cử nhân trong đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.Bachelor': 'Số lượng cử nhân trong đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.Required.Other': 'Số lượng thành viên khác trong đội ngũ đội ngũ là bắt buộc',
  'Mes.TeamOverview.Invalid.Other':
    'Số lượng thành viên khác trong đội ngũ của đội ngũ không hợp lệ',
  'Mes.TeamOverview.NotFound': 'Không tìm thấy loại đội ngũ',
  'Mes.TeamOverview.Create.Successfully': 'Tạo tổng quan đội ngũ thành công',
  'Mes.TeamOverview.Create.Failed': 'Tạo tổng quan đội ngũ thất bại',
  'Mes.TeamOverview.Update.Successfully': 'Cập nhật tổng quan đội ngũ thành công',
  'Mes.TeamOverview.Update.Failed': 'Cập nhật tổng quan đội ngũ thất bại',
  'Mes.TeamOverview.Search.Successfully': 'Xem danh sách tổng quan đội ngũ thành công',
  'Mes.TeamOverview.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.TeamOverview.Delete.Successfully': 'Xóa tổng quan đội ngũ thành công',
  'Mes.TeamOverview.Delete.Failed': 'Xóa tổng quan đội ngũ thất bại',
  'Mes.SocialOrgDivision.NotFound': 'Không tìm thấy loại phân hiệu của Đảng - Đoàn thể',
  'Mes.SocialOrgDivision.Create.Successfully': 'Tạo thành công',
  'Mes.SocialOrgDivision.Create.Failed': 'Tạo thất bại',
  'Mes.SocialOrgDivision.Update.Successfully': 'Cập nhật thành công',
  'Mes.SocialOrgDivision.Update.Failed': 'Cập nhật thất bại',
  'Mes.SocialOrgDivision.Search.Successfully': 'Xem danh sách thành công',
  'Mes.SocialOrgDivision.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.SocialOrgDivision.Delete.Successfully': 'Xóa thành công',
  'Mes.SocialOrgDivision.Delete.Failed': 'Xóa thất bại',
  'Mes.SocialOrgDivision.Repeated.Language': 'Xóa phân hiệu của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgDivision.Required.Translations':
    'Bản dịch của phân hiệu của Đảng - Đoàn thể là bắt buộc',
  'Mes.SocialOrgDivision.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.SocialOrgDivisionTranslation.Required.LanguageCode': 'Mã ngôn ngữ của bản dịch là bắt buộc',
  'Mes.SocialOrgDivisionTranslation.Required.Name':
    'Tên của phân hiệu của Đảng - Đoàn thể là bắt buộc',
  'Mes.SocialOrgDivisionTranslation.OverLength.Name':
    'Tên của phân hiệu của Đảng - Đoàn thể quá dài',
  'Mes.SocialOrgDivisionTranslation.NotFound':
    'Không tìm thấy bản dịch của phân hiệu của Đảng - Đoàn thể',
  'Mes.SocialOrgDivision.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.SocialOrgDivision.Required.PageSize': 'Số trang của phân hiệu của Đảng - Đoàn là bắt buộc',
  'Mes.SocialOrgPeriod.NotFound': 'Không tìm thấy loại giai đoạn của Đảng - Đoàn thể',
  'Mes.SocialOrgPeriod.Create.Successfully': 'Tạo giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgPeriod.Create.Failed': 'Tạo giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgPeriod.Update.Successfully': 'Cập nhật giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgPeriod.Update.Failed': 'Cập nhật giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgPeriod.Search.Successfully':
    'Xem danh sách giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgPeriod.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.SocialOrgPeriod.Delete.Successfully': 'Xóa giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgPeriod.Delete.Failed': 'Xóa giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgPeriod.Repeated.Language': 'Xóa giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgPeriod.Required.Translations':
    'Bản dịch của giai đoạn của Đảng - Đoàn thể là bắt buộc',
  'Mes.SocialOrgPeriod.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.SocialOrgPeriodTranslation.Required.LanguageCode': 'Mã ngôn ngữ của bản dịch là bắt buộc',
  'Mes.SocialOrgPeriodTranslation.Required.Name':
    'Tên của giai đoạn của Đảng - Đoàn thể là bắt buộc',
  'Mes.SocialOrgPeriodTranslation.OverLength.Name': 'Tên của giai đoạn của Đảng - Đoàn thể quá dài',
  'Mes.SocialOrgPeriodTranslation.NotFound':
    'Không tìm thấy bản dịch của giai đoạn của Đảng - Đoàn thể',
  'Mes.SocialOrgPeriod.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.SocialOrgPeriod.Required.PageSize': 'Số trang của giai đoạn của Đảng - Đoàn là bắt buộc',
  'Mes.SocialOrgPeriodTranslation.Invalid.To': 'Thời gian kết thúc của giai đoạn không hợp lệ',
  'Mes.SocialOrgPeriodTranslation.Required.To': 'Thời gian kết thúc của giai đoạn là bắt buộc',
  'Mes.SocialOrgPeriodTranslation.Invalid.From': 'Thời gian bắt đầu của giai đoạn không hợp lệ',
  'Mes.SocialOrgPeriodTranslation.Required.From': 'Thời gian bắt đầu của giai đoạn là bắt buộc',
  'Mes.SocialOrgPeriodTranslation.OverLength.Description': 'Mô tả quá dài',
  'Mes.SocialOrgPeriodTranslation.OverLength.DisplayName': 'Tên hiển thị quá dài',
  'Mes.SocialOrgPeriodTranslation.Invalid.Type': 'Loại của giai đoạn không hợp lệ',
  'Mes.SocialOrgPeriodTranslation.Required.Type': 'Loại của giai đoạn là bắt buộc',
  'Mes.Language.NotFound': 'Không tìm thấy ngôn ngữ',
  'Mes.SocialOrgTopic.NotFound': 'Không tìm thấy loại giai đoạn của Đảng - Đoàn thể',
  'Mes.SocialOrgTopic.Create.Successfully': 'Tạo giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgTopic.Create.Failed': 'Tạo giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgTopic.Update.Successfully': 'Cập nhật giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgTopic.Update.Failed': 'Cập nhật giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgTopic.Search.Successfully':
    'Xem danh sách giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgTopic.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.SocialOrgTopic.Delete.Successfully': 'Xóa giai đoạn của Đảng - Đoàn thể thành công',
  'Mes.SocialOrgTopic.Delete.Failed': 'Xóa giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgTopic.Repeated.Language': 'Xóa giai đoạn của Đảng - Đoàn thể thất bại',
  'Mes.SocialOrgTopic.Required.Translations':
    'Bản dịch của giai đoạn của Đảng - Đoàn thể là bắt buộc',
  'Mes.SocialOrgTopic.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.SocialOrgTopicTranslation.Required.LanguageCode': 'Mã ngôn ngữ của bản dịch là bắt buộc',
  'Mes.SocialOrgTopicTranslation.Required.Name':
    'Tên của giai đoạn của Đảng - Đoàn thể là bắt buộc',
  'Mes.SocialOrgTopicTranslation.OverLength.Name': 'Tên của giai đoạn của Đảng - Đoàn thể quá dài',
  'Mes.SocialOrgTopicTranslation.NotFound':
    'Không tìm thấy bản dịch của giai đoạn của Đảng - Đoàn thể',
  'Mes.SocialOrgTopic.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.SocialOrgTopic.Required.PageSize': 'Số trang của giai đoạn của Đảng - Đoàn là bắt buộc',
  'Mes.Translation.Repeated.Language': 'Ngôn ngữ của bản dịch đã trùng',
  'Mes.Translation.OverLength.Content': 'Nội dung quá dài',
  'Mes.Translation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.Translation.NotFound.Content': 'Không tìm thấy nội dung',
  'Mes.Translation.Required.ContentIndex': 'Vị trí của nội dung là bắt buộc',
  'Mes.Translation.MustBeEmpty.ContentIndex': 'Vị trí của nội dung bỏ để trống',
  'Mes.Translation.Required.ContentPathIndex': 'Vị trí của đường dẫn nội dung là bắt buộc',
  'Mes.Translation.MustBeEmpty.ContentPathIndex': 'Vị trí của đường dẫn nội dung bỏ để trống',
  'Mes.Intro.NotFound': 'Không tìm thấy video tổng quan',
  'Mes.Intro.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.Intro.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.Intro.Required.Contents': 'Nội dung là bắt buộc',
  'Mes.Intro.InvalidQuantity.ContentIndex': 'Số lượng vị trí của nội dung không hợp lệ',
  'Mes.Intro.InvalidQuantity.ContentPathIndex':
    'Số lượng vị trí của đường dẫn nội dung không hợp lệ',
  'Mes.ScreenSaver.NotFound': 'Không tìm thấy màn hình chờ',
  'Mes.ScreenSaver.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.ScreenSaver.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.ScreenSaver.Required.Contents': 'Nội dung là bắt buộc',
  'Mes.ScreenSaver.InvalidQuantity.ContentIndex': 'Số lượng vị trí của nội dung không hợp lệ',
  'Mes.ScreenSaver.InvalidQuantity.ContentPathIndex':
    'Số lượng vị trí của đường dẫn nội dung không hợp lệ',
  'Mes.OrgDivision.NotFound': 'Không tìm thấy loại phân hiệu của bộ máy tổ chức',
  'Mes.OrgDivision.Create.Successfully': 'Tạo thành công',
  'Mes.OrgDivision.Create.Failed': 'Tạo phân hiệu của bộ máy tổ chức thất bại',
  'Mes.OrgDivision.Update.Successfully': 'Cập nhật thành công',
  'Mes.OrgDivision.Update.Failed': 'Cập nhật phân hiệu của bộ máy tổ chức thất bại',
  'Mes.OrgDivision.Search.Successfully': 'Xem danh sách phân hiệu của bộ máy tổ chức thành công',
  'Mes.OrgDivision.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.OrgDivision.Delete.Successfully': 'Xóa thành công',
  'Mes.OrgDivision.Delete.Failed': 'Xóa phân hiệu của bộ máy tổ chức thất bại',
  'Mes.OrgDivision.Repeated.Language': 'Xóa phân hiệu của bộ máy tổ chức thất bại',
  'Mes.OrgDivision.Required.Translations': 'Bản dịch của phân hiệu của bộ máy tổ chức là bắt buộc',
  'Mes.OrgDivision.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.OrgPeriod.NotFound': 'Không tìm thấy loại',
  'Mes.OrgPeriod.Create.Successfully': 'Tạo thành công',
  'Mes.OrgPeriod.Create.Failed': 'Tạo thất bại',
  'Mes.OrgPeriod.Update.Successfully': 'Cập nhật thành công',
  'Mes.OrgPeriod.Update.Failed': 'Cập nhật thất bại',
  'Mes.OrgPeriod.Search.Successfully': 'Xem danh sách thành công',
  'Mes.OrgPeriod.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.OrgPeriod.Delete.Successfully': 'Xóa thành công',
  'Mes.OrgPeriod.Delete.Failed': 'Xóa thất bại',
  'Mes.OrgPeriod.Repeated.Language': 'Xóa thất bại',
  'Mes.OrgPeriod.Required.Translations': 'Bản dịch của là bắt buộc',
  'Mes.OrgPeriod.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.OrgTopic.NotFound': 'Không tìm thấy loại',
  'Mes.OrgTopic.Create.Successfully': 'Tạo thành công',
  'Mes.OrgTopic.Create.Failed': 'Tạo thất bại',
  'Mes.OrgTopic.Update.Successfully': 'Cập nhật thành công',
  'Mes.OrgTopic.Update.Failed': 'Cập nhật thất bại',
  'Mes.OrgTopic.Search.Successfully': 'Xem danh sách thành công',
  'Mes.OrgTopic.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.OrgTopic.Delete.Successfully': 'Xóa thành công',
  'Mes.OrgTopic.Delete.Failed': 'Xóa thất bại',
  'Mes.OrgTopic.Repeated.Language': 'Xóa giai đoạn của bộ máy tổ chức thất bại',
  'Mes.OrgTopic.Required.Translations': 'Bản dịch của giai đoạn của bộ máy tổ chức là bắt buộc',
  'Mes.OrgTopic.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.OrgTopicTranslation.Required.LanguageCode': 'Mã ngôn ngữ của bản dịch là bắt buộc',
  'Mes.OrgTopicTranslation.Required.Name': 'Tên của giai đoạn của bộ máy tổ chức là bắt buộc',
  'Mes.OrgTopicTranslation.OverLength.Name': 'Tên của giai đoạn của bộ máy tổ chức quá dài',
  'Mes.OrgTopicTranslation.NotFound': 'Không tìm thấy bản dịch của giai đoạn của bộ máy tổ chức',
  'Mes.OrgTopic.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.OrgTopic.Required.PageSize': 'Số trang của giai đoạn của bộ máy tổ chức là bắt buộc',
  'Mes.LeadershipPeriod.NotFound': 'Không tìm thấy loại giai đoạn của lãnh đạo',
  'Mes.LeadershipPeriodTranslation.NotFound': 'Không tìm thấy bản dịch của giai đoạn của lãnh đạo',
  'Mes.LeadershipPeriod.Create.Successfully': 'Tạo thành công',
  'Mes.LeadershipPeriod.Create.Failed': 'Tạo thất bại',
  'Mes.LeadershipPeriod.Update.Successfully': 'Cập nhật thành công',
  'Mes.LeadershipPeriod.Update.Failed': 'Cập nhật thất bại',
  'Mes.LeadershipPeriod.Search.Successfully': 'Xem danh sách thành công',
  'Mes.LeadershipPeriod.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.LeadershipPeriod.Delete.Successfully': 'Xóa thành công',
  'Mes.LeadershipPeriod.Delete.Failed': 'Xóa thất bại',
  'Mes.LeadershipPeriod.Repeated.Language': 'Xóa thất bại',
  'Mes.LeadershipPeriod.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.LeadershipPeriod.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.LeadershipPeriodTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.LeadershipPeriodTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.LeadershipPeriodTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.LeadershipPeriod.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.LeadershipPeriod.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.LeadershipPeriodTranslation.Invalid.To': 'Thời gian kết thúc giai đoạn không hợp lệ',
  'Mes.LeadershipPeriodTranslation.Required.To': 'Thời gian kết thúc giai đoạn là bắt buộc',
  'Mes.LeadershipPeriodTranslation.Invalid.From': 'Thời gian bắt đầu giai đoạn không hợp lệ',
  'Mes.LeadershipPeriodTranslation.Required.From': 'Thời gian bắt đầu giai đoạn là bắt buộc',
  'Mes.LeadershipPeriodTranslation.OverLength.Description': 'Mô tả quá dài',
  'Mes.LeadershipPeriodTranslation.OverLength.DisplayName': 'Tên hiển thị quá dài',
  'Mes.LeadershipPeriodTranslation.Invalid.Type': 'Loại giai đoạn không hợp lệ',
  'Mes.LeadershipPeriodTranslation.Required.Type': 'Loại giai đoạn là bắt buộc',
  'Mes.Leadership.NotFound': 'Không tìm thấy loại',
  'Mes.LeadershipTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.Leadership.Create.Successfully': 'Tạo thành công',
  'Mes.Leadership.Create.Failed': 'Tạo thất bại',
  'Mes.Leadership.Update.Successfully': 'Cập nhật thành công',
  'Mes.Leadership.Update.Failed': 'Cập nhật thất bại',
  'Mes.Leadership.Search.Successfully': 'Xem danh sách thành công',
  'Mes.Leadership.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Leadership.Delete.Successfully': 'Xóa thành công',
  'Mes.Leadership.Delete.Failed': 'Xóa thất bại',
  'Mes.Leadership.Repeated.Language': 'Xóa thất bại',
  'Mes.Leadership.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.Leadership.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.LeadershipTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.LeadershipTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.LeadershipTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.Leadership.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.Leadership.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.LeadershipTranslation.Invalid.To': 'Thời gian kết thúc không hợp lệ',
  'Mes.LeadershipTranslation.Required.To': 'Thời gian kết thúc là bắt buộc',
  'Mes.LeadershipTranslation.Invalid.From': 'Thời gian bắt đầu không hợp lệ',
  'Mes.LeadershipTranslation.Required.From': 'Thời gian bắt đầu là bắt buộc',
  'Mes.LeadershipTranslation.OverLength.Description': 'Mô tả quá dài',
  'Mes.LeadershipTranslation.OverLength.DisplayName': 'Tên hiển thị quá dài',
  'Mes.LeadershipTranslation.Invalid.Type': 'Loại không hợp lệ',
  'Mes.LeadershipTranslation.Required.Type': 'Loại là bắt buộc',
  'Mes.LeadershipTranslation.Required.Description': 'Mô tả là bắt buộc',
  'Mes.LeadershipTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.LeadershipTranslation.Required.Position': 'Vị trí là bắt buộc',
  'Mes.LeadershipTranslation.Invalid.Position': 'Vị trí không hợp lệ',
  'Mes.LeadershipTranslation.OverLength.Term': 'Nhiệm kỳ quá dài',
  'Mes.LeadershipTranslation.Required.Term': 'Nhiệm kỳ là bắt buộc',
  'Mes.LeadershipTranslation.Invalid.Term': 'Nhiệm kỳ không hợp lệ',
  'Mes.LeadershipTranslation.OverLength.Avatar': 'Ảnh đại diện quá dài',
  'Mes.LeadershipTranslation.Required.Avatar': 'Ảnh đại diện là bắt buộc',
  'Mes.LeadershipTranslation.Invalid.Avatar': 'Ảnh đại diện không hợp lệ',
  'Mes.Leadership.Invalid.SortOrder': 'Thứ tự săp xếp không hợp lệ',
  'Mes.Leadership.Required.SortOrder': 'Thứ tự săp xếp là bắt buộc',
  'Mes.Leadership.Repeated.SortOrder': 'Thứ tự săp xếp bị lặp lại',
  'Mes.LeadershipBranch.NotFound': 'Không tìm thấy loại của phân hiệu',
  'Mes.LeadershipBranchTranslation.NotFound': 'Không tìm thấy bản dịch của phân hiệu',
  'Mes.LeadershipBranch.Create.Successfully': 'Tạo thành công',
  'Mes.LeadershipBranch.Create.Failed': 'Tạo thất bại',
  'Mes.LeadershipBranch.Update.Successfully': 'Cập nhật thành công',
  'Mes.LeadershipBranch.Update.Failed': 'Cập nhật thất bại',
  'Mes.LeadershipBranch.Search.Successfully': 'Xem danh sách thành công',
  'Mes.LeadershipBranch.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.LeadershipBranch.Delete.Successfully': 'Xóa thành công',
  'Mes.LeadershipBranch.Delete.Failed': 'Xóa thất bại',
  'Mes.LeadershipBranch.Repeated.Language': 'Xóa thất bại',
  'Mes.LeadershipBranch.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.LeadershipBranch.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.LeadershipBranchTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.LeadershipBranchTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.LeadershipBranchTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.LeadershipBranch.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.LeadershipBranch.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.LeadershipBranch.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.LeadershipBranchTranslation.Invalid.To': 'Thời gian kết thúc không hợp lệ',
  'Mes.LeadershipBranchTranslation.Required.To': 'Thời gian kết thúc là bắt buộc',
  'Mes.LeadershipBranchTranslation.Invalid.From': 'Thời gian bắt đầu không hợp lệ',
  'Mes.LeadershipBranchTranslation.Required.From': 'Thời gian bắt đầu là bắt buộc',
  'Mes.LeadershipBranchTranslation.OverLength.DisplayName': 'Tên hiển thị quá dài',
  'Mes.LeadershipBranchTranslation.Invalid.Type': 'Loại không hợp lệ',
  'Mes.LeadershipBranchTranslation.Required.Type': 'Loại là bắt buộc',
  'Mes.LeadershipBranchTranslation.OverLength.Description': 'Mô tả quá dài',
  'Mes.LeadershipBranchTranslation.Required.Description': 'Mô tả là bắt buộc',
  'Mes.LeadershipBranchTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.LeadershipBranchTranslation.Required.Position': 'Vị trí là bắt buộc',
  'Mes.LeadershipBranchTranslation.Invalid.Position': 'Vị trí không hợp lệ',
  'Mes.LeadershipBranchTranslation.OverLength.Term': 'Nhiệm kỳ quá dài',
  'Mes.LeadershipBranchTranslation.Required.Term': 'Nhiệm kỳ là bắt buộc',
  'Mes.LeadershipBranchTranslation.Invalid.Term': 'Nhiệm kỳ không hợp lệ',
  'Mes.LeadershipBranchTranslation.OverLength.Avatar': 'Ảnh đại diện quá dài',
  'Mes.LeadershipBranchTranslation.Required.Avatar': 'Ảnh đại diện là bắt buộc',
  'Mes.LeadershipBranchTranslation.Invalid.Avatar': 'Ảnh đại diện không hợp lệ',
  'Mes.LeadershipBranch.Invalid.SortOrder': 'Thứ tự săp xếp không hợp lệ',
  'Mes.LeadershipBranch.Required.SortOrder': 'Thứ tự săp xếp là bắt buộc',
  'Mes.LeadershipBranch.Repeated.SortOrder': 'Thứ tự săp xếp bị lặp lại',
  'Mes.LeadershipBranch.Required.PeriodId': 'Giai đoạn là bắt buộc',

  'Mes.Region.NotFound': 'Không tìm thấy châu lục',
  'Mes.Country.NotFound': 'Không tìm thấy quốc gia',
  'Mes.Province.NotFound': 'Không tìm thấy Tỉnh/Thành phố',
  'Mes.AchHonoree.Create.Successfully': 'Tạo thành công',
  'Mes.AchHonoree.Create.Failed': 'Tạo thất bại',
  'Mes.AchHonoree.Update.Successfully': 'Cập nhật thành công',
  'Mes.AchHonoree.Update.Failed': 'Cập nhật thất bại',
  'Mes.AchHonoree.Search.Successfully': 'Xem danh sách thành công',
  'Mes.AchHonoree.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.AchHonoree.Delete.Successfully': 'Xóa thành công',
  'Mes.AchHonoree.Delete.Failed': 'Xóa thất bại',
  'Mes.AchHonoree.Repeated.Language': 'Xóa thất bại',
  'Mes.AchHonoree.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.AchHonoree.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.AchHonoreeTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.AchHonoreeTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.AchHonoreeTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.AchHonoreeTranslation.Invalid.VitalStatus': 'Tình trạng sức khỏe thành viên không hợp lệ',
  'Mes.AchHonoreeTranslation.Invalid.RecognitionYear':
    'Năm mà thành viên được công nhận không hợp lệ',
  'Mes.AchHonoreeTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.AchHonoree.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.AchHonoreeTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.AchHonoreeTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.AchHonoree.Required.TitleId': 'Mã là bắt buộc',
  'Mes.AchHonoree.Invalid.SortOrder': 'Thứ tự săp xếp không hợp lệ',
  'Mes.AchHonoree.Required.SortOrder': 'Thứ tự săp xếp là bắt buộc',
  'Mes.AchHonoree.Repeated.SortOrder': 'Thứ tự săp xếp bị lặp lại',
  'Mes.AchievementComposition.Create.Successfully': 'Tạo thành công',
  'Mes.AchievementComposition.Create.Failed': 'Tạo thất bại',
  'Mes.AchievementComposition.Update.Successfully': 'Cập thành công',
  'Mes.AchievementComposition.Update.Failed': 'Cập nhật thất bại',
  'Mes.AchievementComposition.Search.Successfully': 'Xem danh sách thành công',
  'Mes.AchievementComposition.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.AchievementComposition.Delete.Successfully': 'Xóa thành công',
  'Mes.AchievementComposition.Delete.Failed': 'Xóa thất bại',
  'Mes.AchievementComposition.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.AchievementComposition.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.AchievementTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.Achievement.Create.Successfully': 'Tạo thành công',
  'Mes.Achievement.Create.Failed': 'Tạo thất bại',
  'Mes.Achievement.Update.Successfully': 'Cập nhật thành công',
  'Mes.Achievement.Update.Failed': 'Cập nhật thất bại',
  'Mes.Achievement.Search.Successfully': 'Xem danh sách thành công',
  'Mes.Achievement.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Achievement.Delete.Successfully': 'Xóa thành công',
  'Mes.Achievement.Delete.Failed': 'Xóa thất bại',
  'Mes.Achievement.Repeated.Language': 'Xóa thất bại',
  'Mes.Achievement.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.Achievement.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.AchievementTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.AchievementTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.AchievementTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.Achievement.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.AchievementTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.AchievementTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.Achievement.Required.TitleId': 'Mã là bắt buộc',
  'Mes.Achievement.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.AchievementTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.AchievementTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.AchievementTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.AchievementTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.AchievementTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.AchievementTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.AchievementTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.AchievementTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.AchievementTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.Achievement.Required.Type': 'Loại là bắt buộc',
  'Mes.Achievement.Invalid.Type': 'Loại không hợp lệ',
  'Mes.AchIndACollTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.AchIndAColl.Create.Successfully': 'Tạo thành công',
  'Mes.AchIndAColl.Create.Failed': 'Tạo thất bại',
  'Mes.AchIndAColl.Update.Successfully': 'Cập nhật thành công',
  'Mes.AchIndAColl.Update.Failed': 'Cập nhật thất bại',
  'Mes.AchIndAColl.Search.Successfully': 'Xem danh sách thành công',
  'Mes.AchIndAColl.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.AchIndAColl.Delete.Successfully': 'Xóa thành công',
  'Mes.AchIndAColl.Delete.Failed': 'Xóa thất bại',
  'Mes.AchIndAColl.Repeated.Language': 'Xóa thất bại',
  'Mes.AchIndAColl.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.AchIndAColl.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.AchIndACollTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.AchIndACollTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.AchIndACollTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.AchIndAColl.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.AchIndACollTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.AchIndACollTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.AchIndAColl.Required.TitleId': 'Mã là bắt buộc',
  'Mes.AchIndAColl.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.AchIndACollTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.AchIndACollTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.AchIndACollTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.AchIndACollTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.AchIndACollTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.AchIndACollTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.AchIndACollTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.AchIndACollTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.AchIndACollTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.AchIndAColl.Required.Type': 'Loại là bắt buộc',
  'Mes.AchIndAColl.Invalid.Type': 'Loại không hợp lệ',
  'Mes.AchIndAColl.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.AchIndAColl.Required.TypeId': 'Mã là bắt buộc',
  'Mes.AchIndAColl.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.AchIndAColl.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.AchIndAColl.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.AchIndACollTypeTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.AchIndACollType.Create.Successfully': 'Tạo thành công',
  'Mes.AchIndACollType.Create.Failed': 'Tạo thất bại',
  'Mes.AchIndACollType.Update.Successfully': 'Cập nhật thành công',
  'Mes.AchIndACollType.Update.Failed': 'Cập nhật thất bại',
  'Mes.AchIndACollType.Search.Successfully': 'Xem danh sách thành công',
  'Mes.AchIndACollType.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.AchIndACollType.Delete.Successfully': 'Xóa thành công',
  'Mes.AchIndACollType.Delete.Failed': 'Xóa thất bại',
  'Mes.AchIndACollType.Repeated.Language': 'Xóa thất bại',
  'Mes.AchIndACollType.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.AchIndACollType.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.AchIndACollTypeTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.AchIndACollTypeTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.AchIndACollTypeTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.AchIndACollType.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.AchIndACollTypeTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.AchIndACollTypeTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.AchIndACollType.Required.TitleId': 'Mã là bắt buộc',
  'Mes.AchIndACollType.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.AchIndACollTypeTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.AchIndACollTypeTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.AchIndACollTypeTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.AchIndACollTypeTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.AchIndACollTypeTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.AchIndACollTypeTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.AchIndACollTypeTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.AchIndACollTypeTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.AchIndACollTypeTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.AchIndACollType.Required.Type': 'Loại là bắt buộc',
  'Mes.AchIndACollType.Invalid.Type': 'Loại không hợp lệ',
  'Mes.AchIndACollType.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.AchIndACollType.Required.TypeId': 'Mã là bắt buộc',
  'Mes.AchIndACollType.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.AchIndACollType.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.AchIndACollType.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.AchRecognitionYear.Create.Successfully': 'Tạo thành công',
  'Mes.AchRecognitionYear.Create.Failed': 'Tạo thất bại',
  'Mes.AchRecognitionYear.Update.Successfully': 'Cập nhật thành công',
  'Mes.AchRecognitionYear.Update.Failed': 'Cập nhật thất bại',
  'Mes.AchRecognitionYear.Search.Successfully': 'Xem danh sách thành công',
  'Mes.AchRecognitionYear.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.AchRecognitionYear.Delete.Successfully': 'Xóa thành công',
  'Mes.AchRecognitionYear.Delete.Failed': 'Xóa thất bại',
  'Mes.AchRecognitionYear.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.AchRecognitionYear.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.AchRecognitionYear.Required.Year': 'Năm là bắt buộc',
  'Mes.AchRecognitionYear.Invalid.Year': 'Năm không hợp lệ',
  'Mes.AchRecognitionYear.Required.Display': 'Tình trạng hiển là bắt buộc',
  'Mes.AchRecognitionYear.Invalid.Display': 'Tình trạng hiển không hợp lệ',
  'Mes.AchRecognitionYear.Required.Type': 'Loại là bắt buộc',
  'Mes.AchRecognitionYear.Invalid.Type': 'Loại không hợp lệ',
  'Mes.AchTitleTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.AchTitle.Create.Successfully': 'Tạo thành công',
  'Mes.AchTitle.Create.Failed': 'Tạo thất bại',
  'Mes.AchTitle.Update.Successfully': 'Cập nhật thành công',
  'Mes.AchTitle.Update.Failed': 'Cập nhật thất bại',
  'Mes.AchTitle.Search.Successfully': 'Xem danh sách thành công',
  'Mes.AchTitle.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.AchTitle.Delete.Successfully': 'Xóa thành công',
  'Mes.AchTitle.Delete.Failed': 'Xóa thất bại',
  'Mes.AchTitle.Repeated.Language': 'Xóa thất bại',
  'Mes.AchTitle.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.AchTitle.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.AchTitleTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.AchTitleTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.AchTitleTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.AchTitle.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.AchTitleTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.AchTitleTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.AchTitle.Required.TitleId': 'Mã là bắt buộc',
  'Mes.AchTitle.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.AchTitleTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.AchTitleTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.AchTitleTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.AchTitleTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.AchTitleTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.AchTitleTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.AchTitleTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.AchTitleTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.AchTitleTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.AchTitle.Required.Type': 'Loại là bắt buộc',
  'Mes.AchTitle.Invalid.Type': 'Loại không hợp lệ',
  'Mes.AchTitle.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.AchTitle.Required.TypeId': 'Mã là bắt buộc',
  'Mes.AchTitle.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.AchTitle.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.AchTitle.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.AchTitle.Invalid.RecognitionYearId': 'Mã không hợp lệ',
  'Mes.CollaborationTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.Collaboration.Create.Successfully': 'Tạo thành công',
  'Mes.Collaboration.Create.Failed': 'Tạo thất bại',
  'Mes.Collaboration.Update.Successfully': 'Cập nhật thành công',
  'Mes.Collaboration.Update.Failed': 'Cập nhật thất bại',
  'Mes.Collaboration.Search.Successfully': 'Xem danh sách thành công',
  'Mes.Collaboration.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Collaboration.Delete.Successfully': 'Xóa thành công',
  'Mes.Collaboration.Delete.Failed': 'Xóa thất bại',
  'Mes.Collaboration.Repeated.Language': 'Xóa thất bại',
  'Mes.Collaboration.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.Collaboration.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.CollaborationTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.CollaborationTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.CollaborationTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.Collaboration.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.CollaborationTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.CollaborationTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.Collaboration.Required.TitleId': 'Mã là bắt buộc',
  'Mes.Collaboration.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.CollaborationTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.CollaborationTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.CollaborationTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.CollaborationTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.CollaborationTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.CollaborationTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.CollaborationTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.CollaborationTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.CollaborationTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.Collaboration.Required.Type': 'Loại là bắt buộc',
  'Mes.Collaboration.Invalid.Type': 'Loại không hợp lệ',
  'Mes.Collaboration.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.Collaboration.Required.TypeId': 'Mã là bắt buộc',
  'Mes.Collaboration.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.Collaboration.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.Collaboration.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.Collaboration.Required.RecognitionYearId': 'Mã là bắt buộc',
  'Mes.Collaboration.Invalid.RecognitionYearId': 'Mã không hợp lệ',
  'Mes.CollaborationPartnerOrganization.Required.ContentIndex': 'Vị trí của nội dung là bắt buộc',
  'Mes.CollaborationPartnerOrganization.MustBeEmpty.ContentIndex':
    'Vị trí của nội dung bỏ để trống',
  'Mes.CollaborationPartnerOrganization.Required.ContentPathIndex':
    'Vị trí của đường dẫn nội dung là bắt buộc',
  'Mes.CollaborationPartnerOrganization.MustBeEmpty.ContentPathIndex':
    'Vị trí của đường dẫn nội dung bỏ để trống',
  'Mes.InterEduInsTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.InterEduIns.Create.Successfully': 'Tạo thành công',
  'Mes.InterEduIns.Create.Failed': 'Tạo thất bại',
  'Mes.InterEduIns.Update.Successfully': 'Cập nhật thành công',
  'Mes.InterEduIns.Update.Failed': 'Cập nhật thất bại',
  'Mes.InterEduIns.Search.Successfully': 'Xem danh sách thành công',
  'Mes.InterEduIns.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.InterEduIns.Delete.Successfully': 'Xóa thành công',
  'Mes.InterEduIns.Delete.Failed': 'Xóa thất bại',
  'Mes.InterEduIns.Repeated.Language': 'Xóa thất bại',
  'Mes.InterEduIns.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.InterEduIns.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.InterEduInsTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.InterEduInsTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.InterEduInsTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.InterEduIns.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.InterEduInsTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.InterEduInsTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.InterEduIns.Required.TitleId': 'Mã là bắt buộc',
  'Mes.InterEduIns.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.InterEduInsTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.InterEduInsTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.InterEduInsTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.InterEduInsTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.InterEduInsTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.InterEduInsTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.InterEduInsTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.InterEduInsTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.InterEduInsTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.InterEduIns.Required.Type': 'Loại là bắt buộc',
  'Mes.InterEduIns.Invalid.Type': 'Loại không hợp lệ',
  'Mes.InterEduIns.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.InterEduIns.Required.TypeId': 'Mã là bắt buộc',
  'Mes.InterEduIns.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.InterEduIns.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.InterEduIns.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.InterEduIns.Required.RecognitionYearId': 'Mã là bắt buộc',
  'Mes.InterEduIns.Invalid.RecognitionYearId': 'Mã không hợp lệ',
  'Mes.InterEduInsPartnerOrganization.Required.ContentIndex': 'Vị trí của nội dung là bắt buộc',
  'Mes.InterEduInsPartnerOrganization.MustBeEmpty.ContentIndex': 'Vị trí của nội dung bỏ để trống',
  'Mes.InterEduInsPartnerOrganization.Required.ContentPathIndex':
    'Vị trí của đường dẫn nội dung là bắt buộc',
  'Mes.InterEduInsPartnerOrganization.MustBeEmpty.ContentPathIndex':
    'Vị trí của đường dẫn nội dung bỏ để trống',
  'Mes.ActivityPictureTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.ActivityPicture.Create.Successfully': 'Tạo thành công',
  'Mes.ActivityPicture.Create.Failed': 'Tạo thất bại',
  'Mes.ActivityPicture.Update.Successfully': 'Cập nhật thành công',
  'Mes.ActivityPicture.Update.Failed': 'Cập nhật thất bại',
  'Mes.ActivityPicture.Search.Successfully': 'Xem danh sách thành công',
  'Mes.ActivityPicture.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.ActivityPicture.Delete.Successfully': 'Xóa thành công',
  'Mes.ActivityPicture.Delete.Failed': 'Xóa thất bại',
  'Mes.ActivityPicture.Repeated.Language': 'Xóa thất bại',
  'Mes.ActivityPicture.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.ActivityPicture.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.ActivityPictureTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.ActivityPictureTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.ActivityPictureTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.ActivityPicture.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.ActivityPictureTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.ActivityPictureTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.ActivityPicture.Required.TitleId': 'Mã là bắt buộc',
  'Mes.ActivityPicture.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.ActivityPictureTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.ActivityPictureTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.ActivityPictureTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.ActivityPictureTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.ActivityPictureTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.ActivityPictureTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.ActivityPictureTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.ActivityPictureTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.ActivityPictureTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.ActivityPicture.Required.Type': 'Loại là bắt buộc',
  'Mes.ActivityPicture.Invalid.Type': 'Loại không hợp lệ',
  'Mes.ActivityPicture.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.ActivityPicture.Required.TypeId': 'Mã là bắt buộc',
  'Mes.ActivityPicture.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.ActivityPicture.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.ActivityPicture.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.ActivityPicture.Required.RecognitionYearId': 'Mã là bắt buộc',
  'Mes.ActivityPicture.Invalid.RecognitionYearId': 'Mã không hợp lệ',
  'Mes.ActivityPicturePicture.Required.ContentIndex': 'Vị trí của nội dung là bắt buộc',
  'Mes.ActivityPicturePicture.MustBeEmpty.ContentIndex': 'Vị trí của nội dung bỏ để trống',
  'Mes.ActivityPicturePicture.Required.ContentPathIndex':
    'Vị trí của đường dẫn nội dung là bắt buộc',
  'Mes.ActivityPicturePicture.MustBeEmpty.ContentPathIndex':
    'Vị trí của đường dẫn nội dung bỏ để trống',
  'Mes.ExcellentGraduateTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.ExcellentGraduate.Create.Successfully': 'Tạo thành công',
  'Mes.ExcellentGraduate.Create.Failed': 'Tạo thất bại',
  'Mes.ExcellentGraduate.Update.Successfully': 'Cập nhật thành công',
  'Mes.ExcellentGraduate.Update.Failed': 'Cập nhật thất bại',
  'Mes.ExcellentGraduate.Search.Successfully': 'Xem danh sách thành công',
  'Mes.ExcellentGraduate.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.ExcellentGraduate.Delete.Successfully': 'Xóa thành công',
  'Mes.ExcellentGraduate.Delete.Failed': 'Xóa thất bại',
  'Mes.ExcellentGraduate.Repeated.Language': 'Xóa thất bại',
  'Mes.ExcellentGraduate.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.ExcellentGraduate.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.ExcellentGraduateTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.ExcellentGraduateTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.ExcellentGraduateTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.ExcellentGraduate.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.ExcellentGraduateTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.ExcellentGraduateTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.ExcellentGraduate.Required.TitleId': 'Mã là bắt buộc',
  'Mes.ExcellentGraduate.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.ExcellentGraduateTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.ExcellentGraduateTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.ExcellentGraduateTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.ExcellentGraduateTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.ExcellentGraduateTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.ExcellentGraduateTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.ExcellentGraduateTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.ExcellentGraduateTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.ExcellentGraduateTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.ExcellentGraduate.Required.Type': 'Loại là bắt buộc',
  'Mes.ExcellentGraduate.Invalid.Type': 'Loại không hợp lệ',
  'Mes.ExcellentGraduate.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.ExcellentGraduate.Required.TypeId': 'Mã là bắt buộc',
  'Mes.ExcellentGraduate.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.ExcellentGraduate.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.ExcellentGraduate.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.ExcellentGraduate.Required.RecognitionYearId': 'Mã là bắt buộc',
  'Mes.ExcellentGraduate.Invalid.RecognitionYearId': 'Mã không hợp lệ',
  'Mes.ExcellentGraduatePartnerOrganization.Required.ContentIndex':
    'Vị trí của nội dung là bắt buộc',
  'Mes.ExcellentGraduatePartnerOrganization.MustBeEmpty.ContentIndex':
    'Vị trí của nội dung bỏ để trống',
  'Mes.ExcellentGraduatePartnerOrganization.Required.ContentPathIndex':
    'Vị trí của đường dẫn nội dung là bắt buộc',
  'Mes.ExcellentGraduatePartnerOrganization.MustBeEmpty.ContentPathIndex':
    'Vị trí của đường dẫn nội dung bỏ để trống',
  'Mes.CampuseTranslation.NotFound': 'Không tìm thấy bản dịch',
  'Mes.Campuse.Create.Successfully': 'Tạo thành công',
  'Mes.Campuse.Create.Failed': 'Tạo thất bại',
  'Mes.Campuse.Update.Successfully': 'Cập nhật thành công',
  'Mes.Campuse.Update.Failed': 'Cập nhật thất bại',
  'Mes.Campuse.Search.Successfully': 'Xem danh sách thành công',
  'Mes.Campuse.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Campuse.Delete.Successfully': 'Xóa thành công',
  'Mes.Campuse.Delete.Failed': 'Xóa thất bại',
  'Mes.Campuse.Repeated.Language': 'Xóa thất bại',
  'Mes.Campuse.Required.Translations': 'Bản dịch là bắt buộc',
  'Mes.Campuse.InvalidQuantity.Translations': 'Số lượng bản dịch không hợp lệ',
  'Mes.CampuseTranslation.Required.LanguageCode': 'Mã ngôn ngữ bản dịch là bắt buộc',
  'Mes.CampuseTranslation.Required.Name': 'Tên là bắt buộc',
  'Mes.CampuseTranslation.OverLength.Name': 'Tên quá dài',
  'Mes.Campuse.AlreadyExist.Language': 'Đã tồn tại bản dịch',
  'Mes.CampuseTranslation.OverLength.Unit': 'Đơn vị quá dài',
  'Mes.CampuseTranslation.OverLength.Position': 'Vị trí quá dài',
  'Mes.Campuse.Required.TitleId': 'Mã là bắt buộc',
  'Mes.Campuse.Invalid.TitleId': 'Mã không hợp lệ',
  'Mes.CampuseTranslation.Invalid.Title': 'Tiêu đề không hợp lệ',
  'Mes.CampuseTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.CampuseTranslation.OverLength.Title': 'Tiêu đề quá dài',
  'Mes.CampuseTranslation.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.CampuseTranslation.Required.Content': 'Nội dung là bắt buộc',
  'Mes.CampuseTranslation.OverLength.Content': 'Nội dung quá dài',
  'Mes.CampuseTranslation.Invalid.Picture': 'Hình ảnh không hợp lệ',
  'Mes.CampuseTranslation.Required.Picture': 'Hình ảnh là bắt buộc',
  'Mes.CampuseTranslation.OverLength.Picture': 'Hình ảnh quá dài',
  'Mes.Campuse.Required.Type': 'Loại là bắt buộc',
  'Mes.Campuse.Invalid.Type': 'Loại không hợp lệ',
  'Mes.Campuse.Invalid.TypeId': 'Mã không hợp lệ',
  'Mes.Campuse.Required.TypeId': 'Mã là bắt buộc',
  'Mes.Campuse.Required.PageSize': 'Số trang là bắt buộc',
  'Mes.Campuse.Invalid.PageSize': 'Số trang không hợp lệ',
  'Mes.Campuse.Required.Display': 'Tình trạng hiển thị là bắt buộc',
  'Mes.Campuse.Required.RecognitionYearId': 'Mã là bắt buộc',
  'Mes.Campuse.Invalid.RecognitionYearId': 'Mã không hợp lệ',
  'Mes.CampusePartnerOrganization.Required.ContentIndex': 'Vị trí của nội dung là bắt buộc',
  'Mes.CampusePartnerOrganization.MustBeEmpty.ContentIndex': 'Vị trí của nội dung bỏ để trống',
  'Mes.CampusePartnerOrganization.Required.ContentPathIndex':
    'Vị trí của đường dẫn nội dung là bắt buộc',
  'Mes.CampusePartnerOrganization.MustBeEmpty.ContentPathIndex':
    'Vị trí của đường dẫn nội dung bỏ để trống',
  'Mes.Intro.Update.Successfully': 'Cập nhật video thành công',
  'Mes.ScreenSaver.Update.Successfully': 'Cập nhật video thành công',
  'Mes.User.NotFound.Email': 'Không tìm thấy email',
  'Mes.AchTitle.Required.RecognitionYearId': 'Vui lòng nhập năm',
  'Mes.OrgDivision.Required.PeriodId': 'Vui lòng nhập giai đoạn',
  'Mes.SocialOrgDivision.Required.PeriodId': 'Vui lòng nhập giai đoạn',
  'Mes.Alumi.Update.Successfully': 'Cập nhật bố cục thành công',

  'Mes.OrgDivisionTranslation.Required.Name': 'Tiêu đề là bắt buộc',
  'Mes.OrgTopicTranslation.Required.Title': 'Tiêu đề là bắt buộc',
  'Mes.CampuseTranslation.Invalid.Avatar': 'Hình đại diện không đúng định dạng',
  'Mes.User.WasUsed': 'Không thể xóa người dùng đang hoạt động',
};
