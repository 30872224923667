import React from 'react';

import Icon from '@ant-design/icons';

const scientificIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
    <path
      d="M13.166 24.3743C15.7012 22.6197 17.0528 20.8458 17.2202 19.0532C17.387 17.2606 16.8457 15.923 15.5962 15.041"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M14.1328 15.1719C14.829 15.1719 15.4967 14.8953 15.989 14.403C16.4812 13.9107 16.7578 13.2431 16.7578 12.5469C16.7578 11.8507 16.4812 11.183 15.989 10.6907C15.4967 10.1984 14.829 9.92188 14.1328 9.92188C13.4366 9.92188 12.7689 10.1984 12.2767 10.6907C11.7844 11.183 11.5078 11.8507 11.5078 12.5469C11.5078 13.2431 11.7844 13.9107 12.2767 14.403C12.7689 14.8953 13.4366 15.1719 14.1328 15.1719Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M12.168 14.4583L8.9538 17.7116L3.5918 12.3496L14.3158 1.625L19.6778 6.987L16.0687 10.5961"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.8255 15.1211L6.41108 19.5999M1.5 24.3751H22.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);
const ScientificIcon = props => <Icon component={scientificIcon} {...props} />;
export default ScientificIcon;
