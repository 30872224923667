import React from 'react';

import { PieChartOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

export const routerOrganization: IRouter = {
  path: '/organization',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'organization.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <PieChartOutlined />,
  },
  generatePath() {
    return '/organization?tab=1';
  },
};
